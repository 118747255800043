import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useModal from '../../../components/hooks/useModal';
import { IndianRupee } from '../../../utils/common-function';
import RadioButton from '../../../components/button/RadioButton';
import CheckBoxActive from '../../../components/button/CheckBoxActive';
import CheckBoxInactive from '../../../components/button/CheckBoxInactive';
import { CrossSvg, SearchSvg, StarSvg, VerifySvg } from '../../../assets/svg';
import { sortByData, countryData, genderData, languageData, offerData, skillData } from '../../../utils/dbs';
import { api_urls } from '../../../utils/api-urls';
import * as AstrologerActions from '../../../redux/actions/astrologerAction';

const OurConsultant = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openSortByModal, closeSortByModal, SortByModal] = useModal();
    const [openFilterModal, closeFilterModal, FilterModal] = useModal();

    const { astrologerData } = useSelector(state => state?.astrologerReducer);
    const current_user_id = localStorage.getItem('current_user_id');

    const [selectedValue, setSelectedValue] = useState("");
    const handleChange = (data) => {
        setSelectedValue(data?.name + data?.type);
        console.log(data)
    };

    const [activeTab, setActiveTab] = useState(0);
    const filterHead = [{ id: 0, name: 'Skill' }, { id: 1, name: 'Language' }, { id: 2, name: 'Gender' }, { id: 3, name: 'Country' }, { id: 4, name: 'Offer' },]

    const [selectedLanguage, setSelectedLanguage] = useState([])
    const handleSelectedLanguage = (language) => {
        setSelectedLanguage((prevSelected) =>
            prevSelected.includes(language)
                ? prevSelected.filter((item) => item !== language)
                : [...prevSelected, language]
        );
    };

    useEffect(() => {
        //! Dispatching API For Getting Astrologer 
        dispatch(AstrologerActions.getAstrologer());
    }, []);

    return (
        <>
            <section className='px-[100px] py-7 max-sm:px-[20px]'>
              <div className='py-[20px]'>
              <div className="text-center text-3xl font-bold text-[#313131]">
            Our Consultant
            
                </div>
                <div className="text-center text-xl  text-[#616161] max-sm:font-normal max-sm:text-lg">
                Select the best astrologer
                </div>
              </div>

                <article className='flex flex-col gap-7'>
                    
                    <main className='flex flex-wrap gap-8 justify-between'>
                        {astrologerData.map((value, index) => (
                            <main key={index} className='flex-grow flex gap-[5px] justify-between px-5 py-4 rounded-lg bg-white basis-[30%]' style={{ boxShadow: "0 0 10px #bdb5b5" }}>
                                <div className='flex flex-col gap-2'>
                                    <div onClick={() => navigate(`/chat-with-astrologer/${value?.astrologerName?.split(' ')[0]?.toLowerCase()}`, { state: { stateData: value } })} className='cursor-pointer'><img src={api_urls + value?.profileImage} className='h-[65px] w-[65px] rounded-[50%]' /></div>
                                    <div className='flex gap-0 text-gray-600'><StarSvg /><StarSvg /><StarSvg /><StarSvg /><StarSvg /></div>
                                    <div className='text-[12px] text-grey'>111377 orders</div>
                                </div>

                                <div className='flex flex-col gap-1 text-[15px] text-grey'>
                                    <div className='text-base text-black'>{value?.astrologerName}</div>
                                    <div>Vedic, Life, Coach, Fac...</div>
                                    <div>English, Hindi, Sanskrit</div>
                                    {index == 4 && <div>Exp: 5 Years</div>}
                                    <div className='text-primary font-semibold'>{IndianRupee(5)}<span className='text-grey line-through'>21min</span></div>
                                    <div>
                                <div onClick={() => navigate('/product-payment')} className='border border-green-500 text-green-500 px-5 rounded-md flex items-center justify-center max-md:py-1 cursor-pointer'>Select</div>
                                </div>
                                </div>
                                

                                {value?.chat_status == "online" ? <VerifySvg color='green' /> : <VerifySvg color='red' />}
                            </main>
                        ))}
                    </main>
                </article>
            </section>

            <SortByModal width={`w-[300px]`}>
                <div className='flex justify-between items-center py-3 px-5 border-b-[2px]'>
                    <div className='text-lg font-semibold'>SORT BY</div>
                    <div onClick={closeSortByModal} className='cursor-pointer' ><CrossSvg strokeWidth='3' /></div>
                </div>

                <main className='px-5 py-5 pb-7 flex flex-col gap-4'>
                    {sortByData.map((value, index) => (
                        <RadioButton key={index}
                            label={value?.type ? value?.name + ' : ' + value?.type : value?.name}
                            name="custom-radio"
                            value={value?.name + value?.type}
                            checked={selectedValue === value?.name + value?.type}
                            onChange={() => handleChange(value)}
                        />
                    ))}
                </main>
            </SortByModal>

            <FilterModal width={`w-[500px]`}>
                <div className='flex justify-between items-center py-3 px-5 border-b-[2px]'>
                    <div className='text-lg font-semibold'>FILTERS</div>
                    <div onClick={closeFilterModal} className='cursor-pointer' ><CrossSvg strokeWidth='3' /></div>
                </div>

                <main className='flex h-96 border-b-[2px]'>
                    <div className='basis-[30%] border-r-[2px]'>
                        <div className=' bg-greybg'>
                            {filterHead.map((value, index) => (
                                <div key={index} onClick={() => setActiveTab(value?.id)} className={`border-l-[5px] ${activeTab == value?.id ? `border-[#008080] bg-white` : `border-greybg`} px-3 py-2 cursor-pointer`}>{value?.name}</div>
                            ))}
                        </div>
                    </div>
                    <div className='basis-[70%] overflow-auto filter-overflow p-4'>
                        <SwtichTab activeTab={activeTab} handleSelectedLanguage={handleSelectedLanguage} selectedLanguage={selectedLanguage} />
                    </div>
                </main>

                <div className='flex gap-4 py-3 px-4 items-center'>
                    <div className='px-4 text-cyan-900 cursor-pointer'>Reset</div>
                    <div className='flex-1 flex justify-center'>
                        <div className='px-20 py-2 bg-yellow-400 rounded-lg cursor-pointer' style={{ boxShadow: "0 0 5px #bdb5b5" }}>Apply</div>
                    </div>
                </div>
            </FilterModal>
        </>
    )
}

export default OurConsultant;

const SwtichTab = ({ activeTab, handleSelectedLanguage, selectedLanguage }) => {
    switch (activeTab) {
        case 0:
            return <>
                <main className='flex flex-col gap-4'>
                    {skillData.map((value, index) => (
                        <div onClick={() => handleSelectedLanguage(value?.name)} key={index} className='flex gap-2 items-center cursor-pointer'>
                            {selectedLanguage.includes(value.name) ? <CheckBoxActive /> : <CheckBoxInactive />}
                            <div>{value?.name}</div>
                        </div>
                    ))}
                </main>
            </>
        case 1:
            return <>
                <main className='flex flex-col gap-4'>
                    {languageData.map((value, index) => (
                        <div onClick={() => handleSelectedLanguage(value?.name)} key={index} className='flex gap-2 items-center cursor-pointer'>
                            {selectedLanguage.includes(value.name) ? <CheckBoxActive /> : <CheckBoxInactive />}
                            <div>{value?.name}</div>
                        </div>
                    ))}
                </main>
            </>
        case 2:
            return <>
                <main className='flex flex-col gap-4'>
                    {genderData.map((value, index) => (
                        <div onClick={() => handleSelectedLanguage(value?.name)} key={index} className='flex gap-2 items-center cursor-pointer'>
                            {selectedLanguage.includes(value.name) ? <CheckBoxActive /> : <CheckBoxInactive />}
                            <div>{value?.name}</div>
                        </div>
                    ))}
                </main>
            </>
        case 3:
            return <>
                <main className='flex flex-col gap-4'>
                    {countryData.map((value, index) => (
                        <div onClick={() => handleSelectedLanguage(value?.name)} key={index} className='flex gap-2 items-center cursor-pointer'>
                            {selectedLanguage.includes(value.name) ? <CheckBoxActive /> : <CheckBoxInactive />}
                            <div>{value?.name}</div>
                        </div>
                    ))}
                </main>
            </>
        case 4:
            return <>
                <main className='flex flex-col gap-4'>
                    {offerData.map((value, index) => (
                        <div onClick={() => handleSelectedLanguage(value?.name)} key={index} className='flex gap-2 items-center cursor-pointer'>
                            {selectedLanguage.includes(value.name) ? <CheckBoxActive /> : <CheckBoxInactive />}
                            <div>{value?.name}</div>
                        </div>
                    ))}
                </main>
            </>
    }
}