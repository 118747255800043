import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { database, ref, push, onValue, serverTimestamp, set } from '../../config/firebase-config';
import { MinChatUiProvider, MainContainer, MessageInput, MessageContainer, MessageList, MessageHeader } from "@minchat/react-chat-ui";
import Timer from './features/Timer';
import axios from 'axios';
import { api_urls } from '../../utils/api-urls';
import { useSelector } from 'react-redux';
import { generateRandomNumber } from '../../utils/common-function';
import HeaderBG from '../../components/common/HeaderBG';

const Chat = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customer_id = searchParams.get('customer');
    const astrologer_id = searchParams.get('astrologer');
    // const { userCustomerDataById, userAstrologerDataById } = useSelector(state => state?.userReducer);
    const user_type = localStorage.getItem('user_type')
    const current_user_id = localStorage.getItem('current_user_id');
    const current_user_data = JSON.parse(localStorage.getItem('current_user_data'));
    const currentUser = { _id: user_type == 'astrologer' ? 'astro_' + current_user_id : 'customer_' + current_user_id, name: current_user_data?.astrologerName || current_user_data?.customerName };

    // console.log('Local Storage', { current_user_id, current_user_data });
    // console.log('Redux Customer', { current_user_id: userCustomerDataById?._id, current_user_data: userCustomerDataById });
    // console.log('Redux Astrologer', { current_user_id: userAstrologerDataById?._id, current_user_data: userAstrologerDataById });

    // let currentUser;
    // if (userCustomerDataById) {
    //     currentUser = { id: userCustomerDataById?._id, name: userCustomerDataById?.customerName };
    // }
    // if (userAstrologerDataById) {
    //     currentUser = { id: userAstrologerDataById?._id, name: userAstrologerDataById?.astrologerName };
    // }

    const [messages, setMessages] = useState([]);

    const fileInputRef = useRef(null);
    const handleAttachClick = () => { if (fileInputRef.current) fileInputRef.current.click() };

    const handleFileChange = async (e) => {
        console.log("Handle File Change Outside ::: ", e.target.files[0]);
        if (e.target.files[0] && e.target.files.length > 0) {
            console.log("Handle File Change Inside ::: ", e.target.files[0]);

            try {
                // const formData = { fileType: 'image', filePath: e.target.files[0] }
                const formData = new FormData();
                formData.append('fileType', 'image');
                formData.append('filePath', e.target.files[0])
                const { data } = await axios.post(api_urls + 'api/customers/store-file', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                console.log("API Data ::: ", data);

                if (data?.success) {
                    const message = { _id: generateRandomNumber(), text: '', image: api_urls + data?.data?.filePath, user: currentUser, createdAt: new Date().getTime(), addedAt: serverTimestamp(), };
                    console.log('message', message);
                    const chatNode = push(ref(database, `ChatMessages/${chat_id}`));
                    const newKey = chatNode.key;
                    const chatRef = ref(database, `ChatMessages/${chat_id}/${newKey}`);

                    await set(chatRef, { ...message, pending: false, sent: true, received: false });
                } else {
                    console.log("Error")
                }
            } catch (error) {
                console.log("Error  ::: ", error);
            }
        }
    };

    const requestedData = { user_id: customer_id, astroID: astrologer_id };
    const chat_id = `customer_${requestedData?.user_id}_astro_${requestedData?.astroID}`;
    const localRequestedData = JSON.parse(localStorage.getItem('chat_requested_data'));
    // console.log("Local Requested Data ::: ", localRequestedData);

    // console.log("message ::: ", messages);

    //! Initiate Chat
    useEffect(() => {
        const messagesRef = ref(database, `ChatMessages/${chat_id}`);
        onValue(messagesRef, (snapshot) => {
            const data = snapshot.val();
            const loadedMessages = [];

            for (let key in data) {
                const message = data[key];
                const media = message.image && { type: 'image', url: message.image, size: '', name: '' };
                console.log("Single Message ::: ", message)
                console.log("Single Message User ID ::: ", message?.user?._id)

                loadedMessages.push({ ...message, media, createdAt: new Date(message?.createdAt), user: { id: message?.user?._id, name: message?.user?.name } });
            }
            setMessages(loadedMessages);
        });
    }, []);
    console.log('Message ::: ', messages);

    //! Send Chat Message 
    const handleSend = async (text) => {
        const message = { _id: generateRandomNumber(), text, image: '', user: currentUser, createdAt: new Date().getTime(), addedAt: serverTimestamp(), };
        console.log("Genrated Random Number ::: ", generateRandomNumber())
        console.log('message', message)
        const chatNode = push(ref(database, `ChatMessages/${chat_id}`));
        const newKey = chatNode.key;
        const chatRef = ref(database, `ChatMessages/${chat_id}/${newKey}`);

        await set(chatRef, { ...message, pending: false, sent: true, received: false });
    };

    return (
        <>
            <HeaderBG />

            <div className='p-5 pl-10'>
                <MinChatUiProvider theme="#6EA9D7">
                    <MainContainer style={{ height: '75vh', width: "100%" }}>
                        <MessageContainer>
                            <MessageHeader showBack={false} >
                                <Timer requestedData={localRequestedData} />
                            </MessageHeader>
                            <MessageList
                                currentUserId={user_type == 'astrologer' ? 'astro_' + current_user_id : 'customer_' + current_user_id}
                                messages={messages.map(msg => ({ ...msg, }))}
                            />
                            <MessageInput placeholder="Type message here" showAttachButton onAttachClick={handleAttachClick} onSendMessage={handleSend} />

                            <input type='file' accept="image/*" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
                        </MessageContainer>
                    </MainContainer>
                </MinChatUiProvider>
            </div>
        </>
    )
}

export default Chat;