//! Common
export const SET_IS_LOADING = 'SET_IS_LOADING';

//! Auth
//* Customer Auth
export const CUSTOMER_LOGIN = 'CUSTOMER_LOGIN';
export const CUSTOMER_LOGIN_OTP = 'CUSTOMER_LOGIN_OTP';

//* Astrologer Auth
export const ASTROLOGER_LOGIN = 'ASTROLOGER_LOGIN';

//* Logout 
export const USER_LOGOUT = 'USER_LOGOUT';

//! Payment
export const HANDLE_PAYMENT = 'HANDLE_PAYMENT';

//! User 
export const GET_USER_CUSTOMER_BY_ID = 'GET_USER_CUSTOMER_BY_ID';
export const SET_USER_CUSTOMER_BY_ID = 'SET_USER_CUSTOMER_BY_ID';
export const GET_USER_ASTROLOGER_BY_ID = 'GET_USER_ASTROLOGER_BY_ID';
export const SET_USER_ASTROLOGER_BY_ID = 'SET_USER_ASTROLOGER_BY_ID';

//! Astrologer
export const GET_ASTROLOGER = 'GET_ASTROLOGER';
export const SET_ASTROLOGER = 'SET_ASTROLOGER';
export const GET_ASTROLOGER_BY_ID = 'GET_ASTROLOGER_BY_ID';
export const SET_ASTROLOGER_BY_ID = 'SET_ASTROLOGER_BY_ID';
export const GET_ASTROLOGER_REVIEW_BY_ID = 'GET_ASTROLOGER_REVIEW_BY_ID';
export const SET_ASTROLOGER_REVIEW_BY_ID = 'SET_ASTROLOGER_REVIEW_BY_ID';

// TODO : Chat
export const GET_LINKED_PROFILE_FOR_CHAT = 'GET_LINKED_PROFILE_FOR_CHAT';
export const SET_LINKED_PROFILE_FOR_CHAT = 'SET_LINKED_PROFILE_FOR_CHAT';
// export const CREATE_PROFILE_FOR_CHAT = 'CREATE_PROFILE_FOR_CHAT';
// export const INITIATE_CHAT_MESSAGE = 'INITIATE_CHAT_MESSAGE';
export const CHAT_REQUEST_SEND_BY_CUSTOMER = 'CHAT_REQUEST_SEND_BY_CUSTOMER';
export const CHAT_REQUEST_ACCEPT_REJECT_BY_ASTROLOGER = 'CHAT_REQUEST_ACCEPT_REJECT_BY_ASTROLOGER';
export const CHAT_REQUEST_ACCEPT_REJECT_BY_CUSTOMER = 'CHAT_REQUEST_ACCEPT_REJECT_BY_CUSTOMER';
export const SET_CHAT_TIMER_COUNTDOWN = 'SET_CHAT_TIMER_COUNTDOWN';
export const END_CHAT_MESSAGE = 'END_CHAT_MESSAGE';
export const CLOSE_CHAT_MESSAGE = 'CLOSE_CHAT_MESSAGE';

//* Rest 
export const SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE';
export const GET_CHAT_MESSAGE = 'GET_CHAT_MESSAGE';
export const SET_CHAT_MESSAGE = 'SET_CHAT_MESSAGE';

//* Invoice 
export const GET_CHAT_INVOICE_DATA = 'GET_CHAT_INVOICE_DATA';
export const SET_CHAT_INVOICE_DATA = 'SET_CHAT_INVOICE_DATA';

export const GET_CHAT_INVOICE_VISIBILITY = 'GET_CHAT_INVOICE_VISIBILITY';
export const SET_CHAT_INVOICE_VISIBILITY = 'SET_CHAT_INVOICE_VISIBILITY';

//* Rating 
export const GET_ASTROLOGER_RATING_VISIBILITY = 'GET_ASTROLOGER_RATING_VISIBILITY';
export const SET_ASTROLOGER_RATING_VISIBILITY = 'SET_ASTROLOGER_RATING_VISIBILITY';

//! Kundli
export const KUNDLI_GET_BIRTH_DETAIL = 'KUNDLI_GET_BIRTH_DETAIL';
export const KUNDLI_SET_BIRTH_DETAIL = 'KUNDLI_SET_BIRTH_DETAIL';

export const KUNDLI_GET_PANCHANG = 'KUNDLI_GET_PANCHANG';
export const KUNDLI_SET_PANCHANG = 'KUNDLI_SET_PANCHANG';

export const KUNDLI_GET_CHART = 'KUNDLI_GET_CHART';
export const KUNDLI_SET_CHART = 'KUNDLI_SET_CHART';

export const KUNDLI_GET_PLANET = 'KUNDLI_GET_PLANET';
export const KUNDLI_SET_PLANET = 'KUNDLI_SET_PLANET';

export const KUNDLI_GET_KP_PLANET = 'KUNDLI_GET_KP_PLANET';
export const KUNDLI_SET_KP_PLANET = 'KUNDLI_SET_KP_PLANET';

export const KUNDLI_GET_KP_HOUSE_CUP = 'KUNDLI_GET_KP_HOUSE_CUP';
export const KUNDLI_SET_KP_HOUSE_CUP = 'KUNDLI_SET_KP_HOUSE_CUP';

export const KUNDLI_GET_VIMSHOTTARI_DASHA = 'KUNDLI_GET_VIMSHOTTARI_DASHA';
export const KUNDLI_SET_VIMSHOTTARI_DASHA = 'KUNDLI_SET_VIMSHOTTARI_DASHA';

export const KUNDLI_GET_HOUSE_REPORT = 'KUNDLI_GET_HOUSE_REPORT';
export const KUNDLI_SET_HOUSE_REPORT = 'KUNDLI_SET_HOUSE_REPORT';

export const KUNDLI_GET_RASHI_REPORT = 'KUNDLI_GET_RASHI_REPORT';
export const KUNDLI_SET_RASHI_REPORT = 'KUNDLI_SET_RASHI_REPORT';

export const KUNDLI_GET_ASTAK_VARGA = 'KUNDLI_GET_ASTAK_VARGA';
export const KUNDLI_SET_ASTAK_VARGA = 'KUNDLI_SET_ASTAK_VARGA';

export const KUNDLI_GET_SARV_ASTAK = 'KUNDLI_GET_SARV_ASTAK';
export const KUNDLI_SET_SARV_ASTAK = 'KUNDLI_SET_SARV_ASTAK';

export const KUNDLI_GET_ASCENDENT = 'KUNDLI_GET_ASCENDENT';
export const KUNDLI_SET_ASCENDENT = 'KUNDLI_SET_ASCENDENT';

//! Ecommerce
export const GET_PRODUCT_CATEGORY = 'GET_PRODUCT_CATEGORY';
export const SET_PRODUCT_CATEGORY = 'SET_PRODUCT_CATEGORY';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const ADD_TO_CART = 'ADD_TO_CART';
export const GET_CART_DATA = 'GET_CART_DATA';
export const SET_CART_DATA = 'SET_CART_DATA';
export const UPDATE_CART_QUANTITY = 'UPDATE_CART_QUANTITY';
export const ORDER_CART = 'ORDER_CART';

//!Astromall
export const GET_ASTROMALL_DATA = 'GET_ASTRO_MALL_DATA'
export const SET_ASTROMALL_POOJA_DATA = 'SET_ASTROMALL_POOJA_DATA'
export const SET_ASTROMALL_SPELL_DATA = 'SET_ASTROMALL_SPELL_DATA'
export const GET_ASTROLOGER_POOJA_DATA = 'GET_ASTROLOGER_POOJA_DATA'
export const SET_ASTROLOGER_POOJA_DATA = 'SET_ASTROLOGER_POOJA_DATA'
export const ORDER_ASTROLOGER_POOJA = 'ORDER_ASTROLOGER_POOJA'
export const GET_ASTROMALL_HISTORY = 'GET_ASTROMALL_HISTORY'
export const SET_ASTROMALL_HISTORY = 'SET_ASTROMALL_HISTORY'

//! Blogs
export const GET_ASTRO_BLOGS = 'GET_ASTRO_BLOGS';
export const SET_ASTRO_BLOGS = 'SET_ASTRO_BLOGS';

//! Static Page
export const GET_TERMS_AND_CONDITION = 'GET_TERMS_AND_CONDITION';
export const SET_TERMS_AND_CONDITION = 'SET_TERMS_AND_CONDITION';
export const GET_PRIVACY_POLICY = 'GET_PRIVACY_POLICY';
export const SET_PRIVACY_POLICY = 'SET_PRIVACY_POLICY';