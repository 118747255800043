
import React, { useState } from 'react';
import { CrossSvg, StarSvg, VerifySvg } from '../../../assets/svg';
import PhoneInput from 'react-phone-input-2';
import { NavLink } from 'react-router-dom';

const AddAddress = () => {

    const [phoneNumber, setPhoneNumber] = useState('');
    const [chatIntakeDetail, setChatIntakeDetail] = useState({ first_name: '', last_name: '', gender: '', date_of_birth: '', time_of_birth: '', place_of_birth: '', marital_status: '', type_of_concern: '' });

    const handleChatIntakeDetail = (e) => {
        const { name, value } = e.target;
        setChatIntakeDetail({ ...chatIntakeDetail, [name]: value })
    };


    const handleSubmitChatIntakeForm = async () => {
    }

    const handlePhoneChange = (value, country) => {
        // const numberData = value;
        // setPhoneNumber(String(numberData)?.substring(country?.dialCode?.length))
        setPhoneNumber(value)
    };
    


    const [chatIntakeFormModal, setChatIntakeFormModal] = useState(false);
    const handleOpenChatIntakeFormModal = () => setChatIntakeFormModal(true);
    const handleCloseChatIntakeFormModal = () => setChatIntakeFormModal(false);
    const customStyles = { content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', padding: 0, borderRadius: "5px", minWidth: "400px", maxWidth: "900px" }, };

    return(
        <section className='px-[100px] py-7 max-sm:px-[20px]'>
          <div  style={customStyles}>
          <div className='text-2xl text-center py-[10px]'>Add Address</div>
          <div  className='bg-[#F0DF20] pt-[5px] px-[5px] pb-[10px] rounded-xl'> 

          
                <main className='flex flex-col gap-4   p-5 bg-[white] rounded-xl '>
                   

                    <form className='px-5 my-8 flex flex-wrap justify-between gap-6'>
                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>First Name</label>
                            <input name='first_name' value={chatIntakeDetail?.first_name} onChange={handleChatIntakeDetail} type='text' placeholder='First Name' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>
                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>Last Name</label>
                            <input name='last_name' value={chatIntakeDetail?.last_name} onChange={handleChatIntakeDetail} type='text' placeholder='Last Name' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>
                        <div className='basis-[30%] flex flex-col gap-1'>
                      
                        <main className='  flex flex-col'>
                            <div className='text-grey'>Enter your phone number</div>
                            <div className='bg-greybg rounded-lg'>
                                <PhoneInput country={'in'} countryOptions={true}
                                    placeholder='Enter mobile no' value={phoneNumber} onChange={handlePhoneChange}
                                    inputStyle={{ width: '100%', height: '45px', fontSize: "15px", backgroundColor: "#EEEEEE" }}
                                />
                            </div>

                        </main>
                
                        </div>

                        <div className='basis-[30%] flex flex-col gap-1'>
                      
                        <main className=' flex flex-col'>
                            <div className='text-grey'>Enter your alternate phone number</div>
                            <div className='bg-greybg rounded-lg'>
                                <PhoneInput country={'in'} countryOptions={true}
                                    placeholder='Enter mobile no' value={phoneNumber} onChange={handlePhoneChange}
                                    inputStyle={{ width: '100%', height: '45px', fontSize: "15px", backgroundColor: "#EEEEEE" }}
                                />
                            </div>

                        </main>
                
                        </div>
                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>Enter Email Id </label>
                            <input name='place_of_birth' value={chatIntakeDetail?.place_of_birth} onChange={handleChatIntakeDetail} type='text' placeholder='abc@example.com' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>

                        
                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>Flat no.</label>
                            <input name='date_of_birth' value={chatIntakeDetail?.date_of_birth} onChange={handleChatIntakeDetail} type='text' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>
                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>Locality</label>
                            <input name='time_of_birth' value={chatIntakeDetail?.time_of_birth} onChange={handleChatIntakeDetail} type='text' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>

                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>City</label>
                            <input name='time_of_birth' value={chatIntakeDetail?.time_of_birth} onChange={handleChatIntakeDetail} type='text' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>

                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>State</label>
                            <input name='time_of_birth' value={chatIntakeDetail?.time_of_birth} onChange={handleChatIntakeDetail} type='text' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>

                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>Country </label>
                            <input name='time_of_birth' value={chatIntakeDetail?.time_of_birth} onChange={handleChatIntakeDetail} type='text' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>

                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>PinCode </label>
                            <input name='time_of_birth' value={chatIntakeDetail?.time_of_birth} onChange={handleChatIntakeDetail} type='text' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>

                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>Landmark </label>
                            <input name='time_of_birth' value={chatIntakeDetail?.time_of_birth} onChange={handleChatIntakeDetail} type='text' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>
                        
                       
                        

                        <div className='basis-[30%] flex flex-col gap-1'></div>
                    </form>
                    <NavLink to="/deliver-to">
                    <div  className='bg-primary text-center text-white rounded-lg p-2 text-sm cursor-pointer w-[200px] '>Save Address</div>
                    </NavLink>
                </main>

                </div>
            </div>
            </section>
    );
}
export default AddAddress;