import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import OtpInput from 'react-otp-input';
import Logo from '../../assets/images/logo/astro-remedy.png';
import LoginImage from '../../assets/images/auth/Login-Image.jpg';
import DownloadApp from '../cards/DownloadApp';
import * as AuthActions from '../../redux/actions/authAction';
import { generateTokenByRequestPermission } from '../../config/firebase-config';
import { BagSvg, CallSvg, CartSvg, ChatSvg, CrossSvg, HamburgerSvg, NotificationSvg, PersonSvg, SupportSvg } from '../../assets/svg';

Modal.setAppElement('#root');

const Header = () => {
    const navRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { userCustomerDataById, userAstrologerDataById } = useSelector(state => state?.userReducer);

    const [shownav, setShownav] = useState(false);
    const [screenScroll, setScreenScroll] = useState(false);
    const [fcmToken, setFcmToken] = useState(null);

    const [resendTimer, setResendTimer] = useState(30); // Initial timer value in seconds
    const [timerActive, setTimerActive] = useState(true);

    // Todo : Astrolger Login Start
    const [loginAstrologerModal, setLoginAstrologerModal] = useState(false);
    const handleOpenLoginAstrologerModal = () => {
        setShownav(false)
        setLoginAstrologerModal(true)
    };
    const handleCloseLoginAstrologerModal = () => {
        setTimerActive(false);
        setResendTimer(30);
        setLoginAstrologerModal(false);
    }

    const [astroFieldDetail, setAstroFieldDetail] = useState({ email: '', password: '' });
    const handleInputFieldAstrolger = (e) => {
        const { name, value } = e.target;
        setAstroFieldDetail({ ...astroFieldDetail, [name]: value })
    };

    const handleLoginAstrolger = async () => {
        console.log(astroFieldDetail);
        const { email, password } = astroFieldDetail;

        const payload = {
            data: { email, password, webFcmToken: localStorage.getItem('fcm_token') },
            onComplete: () => {
                handleCloseLoginAstrologerModal()
                setAstroFieldDetail({ email: '', password: '' })
                navigate('/')
            }
        }
        dispatch(AuthActions.astrologerLogin(payload));
    };

    // Todo : Download App Modal 
    const [downloadAppModal, setDownloadAppModal] = useState(false);
    const handleOpenDownloadAppModal = () => {
        setShownav(!shownav)
        setDownloadAppModal(true)
    };
    const handleClosedownloadAppModal = () => setDownloadAppModal(false);

    //! Customer Login Start
    const [loginCustomerModal, setLoginCustomerModal] = useState(false);
    const handleOpenLoginCustomerModal = () => {
        setShownav(false)
        setLoginCustomerModal(true)
    };
    const handleCloseLoginCustomerModal = () => {
        setLoginCustomerModal(false);
        setOtpScreen(false);
        setPhoneNumber('');
        setCustomerOtp(null);
    };

    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCodeLength, setCountryCodeLength] = useState('');
    const handlePhoneChange = (value, country) => {
        console.log(`Phone :${value} and Country length : ${country?.dialCode?.length}`)
        setPhoneNumber(value);
        setCountryCodeLength(country?.dialCode?.length);
    };

    const [otpScreen, setOtpScreen] = useState(false);
    const [customerOtp, setCustomerOtp] = useState('');
    const [otpError, setOtpError] = useState('');

    // Validation
    const validateOtp = () => {
        if (!customerOtp) {
            setOtpError(' Please Enter OTP ');
            return false;
        }
        // Example pattern check, adjust as per your requirement
        const otpPattern = /^\d{4}$/;
        if (!otpPattern.test(customerOtp)) {
            setOtpError('OTP format is incorrect');
            return false;
        }
        setOtpError('');
        return true;
    };

    // handelTimer
    useEffect(() => {
        let intervalId;

        if (timerActive) {
            intervalId = setInterval(() => {
                setResendTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [timerActive]);

    const handleResendOtp = () => {

        // Reset timer and start countdown again
        setResendTimer(30);
        setTimerActive(true);

    };

    // Handellogin
    const handleSubmitOtp = (validateOtp, phoneNumber, setOtpScreen, handleCloseLoginCustomerModal, setPhoneNumber) => {
        if (validateOtp()) {
            dispatch(AuthActions.customerLoginOtp({
                data: {
                    phoneNumber: String(phoneNumber)?.substring(2),
                    webFcmToken: localStorage.getItem('fcm_token'),
                    device_id: 'device_id'
                },
                onComplete: () => {
                    setOtpScreen(false);
                    handleCloseLoginCustomerModal();
                    setPhoneNumber('');
                    setCustomerOtp(null);
                    setTimerActive(false);
                    setResendTimer(30);
                    navigate('/')
                },
                onClick: () => {
                    setTimerActive(false);
                    setResendTimer(30);
                },
            }));
        }
    };

    // handleCustomerLogin
    const handleCustomerLogin = (phoneNumber) => {
        dispatch(AuthActions.customerLogin({
            data: { phoneNumber: String(phoneNumber)?.substring(countryCodeLength) },
            onComplete: () => {
                setOtpScreen(true)
                setTimerActive(true);
                setResendTimer(30);
            },
        }));
    };

    //! Handle Resize and Scroll Event Listener 
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 500) {
                setShownav(false)
            }
        };

        const handleScroll = () => {
            if (window.scrollY > 60) setScreenScroll(true);
            else setScreenScroll(false);
        }

        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setShownav(false);
            }
        };

        window.addEventListener('resize', handleResize);
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('scroll', handleScroll);

        if (shownav) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('scroll', handleScroll);
        };
    }, [shownav]);

    //! Generate FCM Token 
    useEffect(() => {
        const getToken = async () => {
            const token = await generateTokenByRequestPermission();
            console.log("Header Token ::: ", token);
            if (token) {
                setFcmToken(token)
            }
        }
        getToken();
    }, [fcmToken]);

    return (
        <>
            <header className={`${screenScroll ? 'bg-white shadow-xl' : 'bg-transparent text-white'} fixed w-full z-[100] top-0 px-[100px] max-md:px-2 py-[15px] transition-all duration-300`}>
                {/* <header className={`${screenScroll ? 'fixed w-full z-[1000] top-0 bg-white' : 'max-md:w-[99vw] bg-transparent'} text-black px-[100px] max-md:px-2 py-[15px] shadow-xl transition-all duration-500 ease-in-out`}> */}
                <main className='flex flex-wrap justify-between items-center gap-5'>
                    <Link to={'/'} ><img src={Logo} className='h-16 max-md:h-10' /></Link>

                    <nav className='flex flex-col max-xl:hidden items-end gap-[7px]'>
                        <main className='flex max-md:flex-wrap items-center gap-7 font-[400] relative max-md:hidden'>
                            <div className='cursor-pointer border-b-2 border-transparent' onClick={() => setDownloadAppModal(true)}><div>Free Kundli</div></div>
                            <div className='cursor-pointer border-b-2 border-transparent' onClick={() => setDownloadAppModal(true)}><div>Kundli Matching</div></div>
                            <div className='cursor-pointer border-b-2 border-transparent' onClick={() => setDownloadAppModal(true)}><div>Book a Pooja</div></div>
                            <NavLink to="/blog" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "border-b-2 border-black" : "border-b-2 border-transparent hover:border-black"}>Blog</NavLink>

                            {!userCustomerDataById && !userAstrologerDataById && <div className='flex items-center gap-1'>
                                <div className='group/item'>
                                    <div className='flex items-center gap-1 cursor-pointer'><PersonSvg /><div>Login</div></div>
                                    <div className='bg-primary text-white p-2 absolute z-40 top-7 right-0 invisible group-hover/item:visible flex flex-col gap-2' style={{ boxShadow: "0 0 15px grey" }}>
                                        <button disabled={fcmToken == null} onClick={handleOpenLoginCustomerModal} className={`px-3 py-2  ${fcmToken == null ? 'cursor-not-allowed' : 'hover:text-primary hover:bg-white'}`}>Login as Customer</button>
                                        <button disabled={fcmToken == null} onClick={handleOpenLoginAstrologerModal} className={`px-3 py-2  ${fcmToken == null ? 'cursor-not-allowed' : 'hover:text-primary hover:bg-white'}`}>Login as Astrologer</button>
                                    </div>
                                </div>
                            </div>}

                            {userAstrologerDataById && <div onClick={() => dispatch(AuthActions.userLogout({ onComplete: () => navigate('/') }))} className='flex items-center gap-1 cursor-pointer'>
                                <PersonSvg /> <div>{userAstrologerDataById?.astrologerName}</div>
                            </div>}

                            {userCustomerDataById && <div onClick={() => dispatch(AuthActions.userLogout({ onComplete: () => navigate('/') }))} className='flex items-center gap-1 cursor-pointer'>
                                <PersonSvg /> <div>{userCustomerDataById?.customerName}</div>
                            </div>}
                        </main>

                        {userCustomerDataById && <main className='flex max-md:flex-wrap items-center gap-7 font-[400] relative max-md:hidden'>
                            <div className='flex items-center gap-1'>
                                <ChatSvg /><NavLink to={'/chat-with-astrologer'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "border-b-2 border-black" : "border-b-2 border-transparent hover:border-black"}>Chat with Astrologer</NavLink>
                            </div>
                            <div className='flex items-center gap-1 cursor-pointer' onClick={() => setDownloadAppModal(true)}><CallSvg /><div>Talk to Astrologer</div></div>
                            <div className='flex items-center gap-1'>
                                <BagSvg /><NavLink to={'/astro-mall'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "border-b-2 border-black" : "border-b-2 border-transparent hover:border-black"}>Astromall</NavLink>
                            </div>
                            <div className='flex items-center gap-1'>
                                <CartSvg /><NavLink to={'/cart'} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "border-b-2 border-black" : "border-b-2 border-transparent hover:border-black"}>My Cart</NavLink>
                            </div>
                        </main>}
                    </nav>

                    <div onClick={() => setShownav(!shownav)} className={`cursor-pointer xl:hidden ${shownav == true && 'invisible'}`}><HamburgerSvg h={'30'} w={'30'} /></div>


                    <main ref={navRef} className={`pb-40 flex flex-col gap-5 p-5 absolute h-full bg-white text-black border-r border-primary shadow-lg top-0 z-50 min-h-[100vh] w-[80vw] transition-all duration-500 overflow-y-scroll ${shownav ? 'left-0' : 'left-[-80vw]'}`}>

                        <div onClick={() => setShownav(!shownav)} className='flex items-center justify-center gap-2 text-sm font-semibold cursor-pointer'>CLOSE <CrossSvg w={'20'} /></div>
                        <div className='text-center font-semibold text-sm'>WHAT ARE YOU LOOKING FOR?</div>

                        <div className='flex flex-col'>
                            {userCustomerDataById && <>
                                <div className='flex items-center gap-1  border-b py-4'>
                                    <div className='border-b-2 border-white'><SupportSvg /></div> <NavLink onClick={() => setShownav(!shownav)} to="/chat-with-astrologer" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "text-primary" : "text-black"}>Chat with Astrologer</NavLink>
                                </div>

                                <div className='flex items-center gap-1  border-b py-4'>
                                    <div className='border-b-2 border-white'><SupportSvg /></div><div className='cursor-pointer' onClick={() => handleOpenDownloadAppModal()}><div>Talk to Astrologer</div></div>
                                </div>

                                <div className='flex items-center gap-1  border-b py-4'>
                                    <div className='border-b-2 border-white'><SupportSvg /></div> <NavLink onClick={() => setShownav(!shownav)} to="/astro-mall" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "text-primary" : "text-black"}>Astromall</NavLink>
                                </div>

                                <div className='flex items-center gap-1  border-b py-4'>
                                    <div className='border-b-2 border-white'><SupportSvg /></div>  <NavLink onClick={() => setShownav(!shownav)} to="/cart" className='cursor-pointer'>Your Cart</NavLink>
                                </div>
                            </>}

                            <div className='flex items-center gap-1  border-b py-4'>
                                <div className='border-b-2 border-white'><SupportSvg /></div><NavLink onClick={() => setShownav(!shownav)} to="/blog" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "text-primary" : "text-black"}>Blog</NavLink>
                            </div>

                            <div className='flex items-center gap-1  border-b py-4'>
                                <div className='border-b-2 border-white'><SupportSvg /></div><div className='cursor-pointer' onClick={() => handleOpenDownloadAppModal()}><div>Book a Pooja</div></div>
                            </div>

                            <div className='flex items-center gap-1  border-b py-4'>
                                <div className='border-b-2 border-white'><SupportSvg /></div><div className='cursor-pointer' onClick={() => handleOpenDownloadAppModal()}><div>Free Kundli</div></div>
                            </div>

                            <div className='flex items-center gap-1  border-b py-4'>
                                <div className='border-b-2 border-white'><SupportSvg /></div><div className='cursor-pointer' onClick={() => handleOpenDownloadAppModal()}><div>Kundli Matching</div></div>
                            </div>

                            {userAstrologerDataById || userCustomerDataById ?
                                <>
                                    <div className='flex items-center gap-1  border-b py-4'>
                                        <div className='border-b-2 border-white'><SupportSvg /></div><div onClick={() => dispatch(AuthActions.userLogout({ onComplete: () => navigate('/') }))} className='cursor-pointer'>Logout</div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='flex items-center gap-1  border-b py-4'>
                                        <div className='border-b-2 border-white'><SupportSvg /></div><div onClick={handleOpenLoginCustomerModal} className='cursor-pointer'>Login as Customer</div>
                                    </div>
                                    <div className='flex items-center gap-1 border-b py-4'>
                                        <div className='border-b-2 border-white'><SupportSvg /></div><div onClick={handleOpenLoginAstrologerModal} className='cursor-pointer'>Login as Astrologer</div>
                                    </div>
                                </>
                            }
                        </div>
                    </main>
                </main>
            </header>

            {shownav && (<div className="fixed top-0 left-0 w-full h-full transition-all ease-in duration-300 bg-black bg-opacity-50 z-40" />)}

            {/* Astrologer */}
            <Modal isOpen={loginAstrologerModal} className="modal-content" overlayClassName="modal-overlay" closeTimeoutMS={200} >
                <section className="relative flex items-center justify-center bg-white max-md:p-5">
                    <div onClick={handleCloseLoginAstrologerModal} className='cursor-pointer absolute text-primary right-5 top-5' ><CrossSvg strokeWidth='3' /></div>

                    <article className="rounded-lg overflow-hidden max-w-4xl w-full">
                        <main className='flex'>
                            <div className='basis-[45%] hidden md:block'>
                                <img src={LoginImage} className="h-full w-full object-cover" />
                            </div>
                            <div className='basis-full md:basis-[55%] flex flex-col justify-center p-8'>
                                <div className='flex justify-center mb-8'>
                                    <img src={Logo} className="h-14" />
                                </div>
                                <div className='flex flex-col gap-4'>
                                    <input name='email' value={astroFieldDetail?.email} onChange={handleInputFieldAstrolger} type='email' placeholder='Email' className='w-full text-sm px-4 py-2 border border-gray-300  focus:border-primary rounded-md focus:outline-none' />

                                    <input name='password' value={astroFieldDetail?.password} onChange={handleInputFieldAstrolger} type='text' placeholder='Password' className='w-full text-sm px-4 py-2 border border-gray-300 focus:border-primary rounded-md focus:outline-none' />

                                    <button onClick={handleLoginAstrolger} className="w-full shadow-lg bg-primary hover:bg-primary focus:shadow-outline focus:outline-none text-white py-2 px-4 rounded transition duration-300 transform hover:scale-95" type="submit">Login</button>

                                    <div className='text-[12px] text-grey'>By login, you agree to our <span className='underline text-blue-700'>Terms of Use</span> and <span className='underline text-blue-700'>Privacy Policy</span></div>
                                </div>
                            </div>
                        </main>
                    </article>
                </section>
            </Modal>

            {/* Customer */}
            <Modal isOpen={loginCustomerModal} className="modal-content" overlayClassName="modal-overlay" closeTimeoutMS={200}>
                <section className="relative flex items-center justify-center bg-white max-md:p-5">
                    <div onClick={handleCloseLoginCustomerModal} className='cursor-pointer absolute text-primary right-5 top-5' ><CrossSvg strokeWidth='3' /></div>

                    <article className="rounded-lg overflow-hidden max-w-4xl w-full">
                        <main className='flex'>
                            <div className='basis-[45%] hidden md:block'>
                                <img src={LoginImage} className="h-full w-full object-cover" />
                            </div>
                            <div className='basis-full md:basis-[55%] flex flex-col justify-center p-8'>
                                <div className='flex justify-center mb-8'>
                                    <img src={Logo} className="h-14" />
                                </div>
                                {otpScreen ?
                                    <main className='flex flex-col gap-4'>
                                        <div className='flex flex-col items-center justify-center gap-3'>
                                            <div className='text-black'>Enter your OTP</div>
                                            <OtpInput value={customerOtp} onChange={setCustomerOtp} numInputs={4} renderSeparator={<span>-</span>} renderInput={(props) => <input {...props} onKeyDown={(e) => e.key === 'Enter' && handleSubmitOtp(validateOtp, phoneNumber, setOtpScreen, handleCloseLoginCustomerModal, setPhoneNumber)} className='border-2 outline-none text-center rounded-md' style={{ height: '40px', width: '40px' }} />} />
                                        </div>
                                        <div className=' text-green-700 text-sm text-right'>
                                            {resendTimer > 0 ? `Resend OTP in ${resendTimer} seconds` : (<button onClick={handleResendOtp} className='text-green-700  text-sm  cursor-pointer hover:text-green-600' >Resend OTP</button>)}
                                        </div>

                                        <button onClick={() => handleSubmitOtp(validateOtp, phoneNumber, setOtpScreen, handleCloseLoginCustomerModal, setPhoneNumber)} className="w-full shadow-lg bg-primary hover:bg-primary focus:shadow-outline focus:outline-none text-white text-sm py-2 px-4 rounded transition duration-300 transform hover:scale-95" type="submit">Submit</button>
                                    </main>
                                    :
                                    <div className='flex flex-col gap-4'>
                                        <div className='text-black'>You will receive a 4 digit code for verification</div>
                                        <PhoneInput country={'in'} countryOptions={true} placeholder='Enter mobile no' value={phoneNumber} onChange={handlePhoneChange} onKeyDown={(e) => { e.key === 'Enter' && handleCustomerLogin(phoneNumber) }} inputStyle={{ width: '100%', height: '45px', fontSize: "15px", backgroundColor: "#EEEEEE" }} />
                                        <button onClick={() => handleCustomerLogin(phoneNumber)} className="w-full shadow-lg bg-primary hover:bg-primary focus:shadow-outline focus:outline-none text-white text-sm py-2 px-4 rounded transition duration-300 transform hover:scale-95" type="submit">GET OTP</button>

                                        <div className='text-[12px] text-grey'>By login, you agree to our <span className='underline text-blue-700'>Terms of Use</span> and <span className='underline text-blue-700'>Privacy Policy</span></div>
                                    </div>}
                            </div>
                        </main>
                    </article>
                </section>
            </Modal>

            {/* Download App */}
            <DownloadApp isOpen={downloadAppModal} handleCloseModal={handleClosedownloadAppModal} />
        </>
    )
}

export default Header;