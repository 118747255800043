import React from "react";
import cardimg from '../../../assets/images/cardBlog/cardimg.jpg'
import whatsaap from '../../../assets/images/cardBlog/whatsapp.png'
import facebook from '../../../assets/images/cardBlog/facebook.png'
import twitter from '../../../assets/images/cardBlog/twitter.png'
import linkedin from '../../../assets/images/cardBlog/linkedin.png'
import { NavLink } from "react-router-dom";
import RecentBlog from "../recent-blog";

const SingleBlog = ({ discription }) => {

    return ( 
        <section className='px-[20px] py-[50px] max-md:px-[20px]'>
            <img src={cardimg} className="w-[100%]  h-[500px] rounded-lg" />
            <main className=" flex flex-wrap gap-3 py-[20px]">
                <div>
                    <img src={whatsaap} className=" w-[35px] h-[35px]  hover:opacity-50" />
                </div>
                <div>
                    <img src={facebook} className=" w-[35px] h-[35px] hover:opacity-50" />
                </div>
                <div>
                    <img src={twitter} className=" w-[35px] h-[35px] hover:opacity-50" />
                </div>
                <div>
                    <img src={linkedin} className=" w-[35px] h-[35px] hover:opacity-50" />
                </div>

            </main>
            <div>
                {/* {discription} */}
                <p className="text-gray-500  text-lg pb-[10px]" >
                    Are you someone who constantly dreams of far-off lands, exotic cultures, and thrilling adventures? If your zodiac sign plays a significant role in shaping your personality and desires, you might find yourself more inclined to travel than others. Let’s delve into the astrology of wanderlust and discover the 4 zodiac signs most likely to be packing their bags and exploring the world.
                </p>
                <h1 className="text-[#212529]  py-[10px] text-2xl"> 1. Sagittarius: The Ultimate Explorer </h1>
                <p className="text-gray-500  text-lg" >
                    Sagittarius, represented by the archer, is known for their love of freedom and exploration. Ruled by Jupiter, the planet of expansion and adventure, Sagittarians are natural-born travelers. They crave new experiences, cultural immersion, and the thrill of discovering the unknown. Their optimistic and adventurous nature often leads them to far-flung destinations, where they can satisfy their wanderlust and expand their horizons.
                </p>

                <div  className="text-gray-600 font-semibold py-[30px] text-xl" >
                    <p> Want To Know About You Love Life?  <span className="text-[#BBAB21] cursor-pointer" > <NavLink to =""> Talk To our astrologer </NavLink></span></p>
                </div>

                <h1 className="text-[#212529]  py-[10px] text-2xl"> 2. Gemini: The Curious Wanderer</h1>
                <p className="text-gray-500  text-lg" >
                  Gemini, symbolized by the twins, is ruled by Mercury, the planet of communication and curiosity. Geminis are known for their restless minds and insatiable desire to learn and explore. They thrive on change and diversity, making them eager travelers who seek intellectual stimulation and new perspectives. Geminis are likely to hop from one destination to another, soaking up knowledge and connecting with people from all walks of life.
                </p>

                <div  className="text-gray-600 font-semibold py-[30px] text-xl" >
                    <p> Also Read:  <span className="text-[#BBAB21] cursor-pointer" > <NavLink to =""> 4 Zodiac Signs Who Fall in Love Fast </NavLink></span></p>
                </div>

                <h1 className="text-[#212529]  py-[10px] text-2xl"> 3. Gemini: The Curious Wanderer</h1>
                <p className="text-gray-500  text-lg" >
                  Gemini, symbolized by the twins, is ruled by Mercury, the planet of communication and curiosity. Geminis are known for their restless minds and insatiable desire to learn and explore. They thrive on change and diversity, making them eager travelers who seek intellectual stimulation and new perspectives. Geminis are likely to hop from one destination to another, soaking up knowledge and connecting with people from all walks of life.
                </p>

                <div  className="text-gray-600 font-semibold py-[30px] text-xl" >
                    <p> Want To Know About You Love Life?  <span className="text-[#BBAB21] cursor-pointer" > <NavLink to =""> Talk To our astrologer </NavLink></span></p>
                </div>

                <h1 className="text-[#212529]  py-[10px] text-2xl"> 4. Gemini: The Curious Wanderer</h1>
                <p className="text-gray-500  text-lg" >
                  Gemini, symbolized by the twins, is ruled by Mercury, the planet of communication and curiosity. Geminis are known for their restless minds and insatiable desire to learn and explore. They thrive on change and diversity, making them eager travelers who seek intellectual stimulation and new perspectives. Geminis are likely to hop from one destination to another, soaking up knowledge and connecting with people from all walks of life.
                </p>

                <div  className="text-gray-600 font-semibold py-[30px] text-xl pb-[20px]" >
                    <p>For interesting astrology videos, follow us on   <span className="text-[#BBAB21] cursor-pointer" > <NavLink to =""> Instagram. </NavLink></span></p>
                </div>

                <main className=" flex flex-wrap gap-3 pb-[10px]">
                <div>
                    <img src={whatsaap} className=" w-[35px] h-[35px]  hover:opacity-50" />
                </div>
                <div>
                    <img src={facebook} className=" w-[35px] h-[35px] hover:opacity-50" />
                </div>
                <div>
                    <img src={twitter} className=" w-[35px] h-[35px] hover:opacity-50" />
                </div>
                <div>
                    <img src={linkedin} className=" w-[35px] h-[35px] hover:opacity-50" />
                </div>

            </main>
            <p  className=" pt-[10px]" > Posted On - June 19, 2024 | Posted By - Jyoti | Read By -  63,313  </p>
                

            </div>

        </section>

    );

};

export default SingleBlog;