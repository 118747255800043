import React from 'react';
import { ViewSvg } from '../../assets/svg';

const BlogCard = ({ img, views, description, name, time, onClick }) => {
    return (
        <section className="relative flex flex-col bg-[#fff] rounded-2xl w-[279px] h-[300px] shadow-md hover:shadow-lg"
        onClick={onClick}>
            <div className='absolute top-2 right-2 flex items-center rounded-xl bg-[#EAE8E6] p-1'>
                <ViewSvg className='mr-1' />
                <p className='text-xs ml-1'>{views}</p>
            </div>
            <div className="overflow-hidden rounded-t-2xl">
                <img src={img} className='h-48 w-full rounded-t-2xl transform hover:scale-105 transition duration-300' alt="Blog" />
            </div>
            <div className='flex flex-col p-4 flex-grow bg-[#fff] rounded-b-2xl'>
                <p className="text-base mb-1">{description}</p>
                <div className='flex justify-between mt-auto pt-1'>
                    <p className='text-gray-500 text-sm'>{name}</p>
                    <p className='text-gray-500 text-sm'>{time}</p>
                </div>
            </div>
        </section>
    );
}

export default BlogCard;
