import React from "react";
import PaymentCard from "../../../components/cards/PaymentCard";
import Upi from '../../../assets/images/cardLogo/UPI-Logo.png';
import CreditCard from '../../../assets/images/cardLogo/cards.png';
import bank from '../../../assets/images/cardLogo/bank.png';
import wallet from '../../../assets/images/cardLogo/wallet.png'

const PaymentOption = () => {
    const cardData = [
        { cardName: 'UPI', img: Upi },
        { cardName: 'Credit/Debit Card', img: CreditCard },
        { cardName: 'Net Banking', img: bank },
        { cardName: 'Other Wallets', img: wallet },

    ];

    return (
        <>
            <section className='px-[100px] py-7 max-sm:px-[20px]'>
                <div className="rounded-xl bg-[#F0DF20] pb-[3px]">
                    <h4 className="py-[10px] text-center text-xl"> Payment Information </h4>
                    <div className=" p-[10px] mx-[3px] mt-[3px] bg-white rounded-b-xl ">
                        <div className="overflow-x-auto">
                            <table className="w-full table-fixed">
                                <thead>
                                    <tr className="border-b-2 border-gray-200">
                                        <th className="text-left">PRODUCT</th>
                                        <th className="text-left" >ASTROLOGER</th>
                                        <th className="text-left" >AMOUNT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border-b border-gray-200">
                                        <td className="py-[10px]">Love Honey Spell</td>
                                        <td >Evanka</td>
                                        <td >₹ 6666</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <main className="flex flex-wrap gap-9 ">
                            <div className="flex-[50]  flex-col justify-between ">
                                <div className="flex flex-wrap  py-[20px] justify-between ">
                                    <div className="flex-[50]" >
                                        Love Honey Spell
                                    </div>
                                    <div className="flex-[50]">
                                        ₹ 6666.00
                                    </div>
                                </div>
                                  <hr/>
                                <div className="flex flex-wrap py-[20px] justify-between ">
                                    <div className="flex-[50]" >
                                    Total Amount
                                    </div>
                                    <div className="flex-[50]">
                                    ₹ 6666.00
                                    </div>
                                </div>

                                <div className="flex flex-wrap py-[20px]  justify-between">
                                    <div className="flex-[50]" >
                                    GST @18%
                                    </div>
                                    <div className="flex-[50]">
                                    ₹ 1199.88
                                    </div>
                                </div>
                                <hr/>

                                <div className="flex flex-wrap py-[20px] justify-between ">
                                    <div className="flex-[50] font-bold" >
                                    Total Payable Amount
                                    </div>
                                    <div className="flex-[50] font-bold">
                                    ₹ 7865.88
                                    </div>
                                </div>


                            </div>
                            <div className="flex flex-wrap flex-[50] justify-between ">
                                <main className="flex flex-wrap gap-2 mt-4">
                                    {cardData.map((data, index) => (
                                        <div key={index} className="flex-[45%]">
                                            <PaymentCard cardName={data.cardName} img={data.img} />
                                        </div>
                                    ))}

                                </main>

                            </div>

                        </main>


                    </div>


                </div>
            </section>
        </>
    );
}

export default PaymentOption;
