import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LandingPage from './pages/landing-page';
import ChatWithAstrologer from './pages/chat-with-astrologer';
import PriceList from './pages/add-money/price-list';
import PaymentDetails from './pages/add-money/payment-details';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import SingleAstrologer from './pages/chat-with-astrologer/single-astrologer';
import MyWallet from './pages/my-wallet';
import PaymentLog from './pages/my-wallet/payment-logs';
import { generateTokenByRequestPermission, onMessageListener } from './config/firebase-config';
import { useDispatch, useSelector } from 'react-redux';
import SocketService from './utils/services/socket-service';
import Chat from './pages/chat';
import AstroMall from './pages/astro-mall';
import Blogs from './pages/blogs';
import Home from './pages/blogs/home';
import SingleBlogPage from './pages/Single-blog-page';
import SingleBlog from './pages/Single-blog-page/single-blog';
import CustomerAcceptReject from './pages/chat/customer-accept-reject';
import AstrologerAcceptReject from './pages/chat/astrologer-accept-reject';
import ChatInvoiceModal from './components/modal/ChatInvoiceModal';
import RatingModal from './components/modal/RatingModal';
import IntakeDetails from './pages/chat/intake-details';
import OurConsultant from './pages/astro-mall/our-consultant';
import PaymentOption from './pages/astro-mall/product-payment';
import AddAddress from './pages/astro-mall/add-address';
import Deliver from './pages/astro-mall/deliver-to';

import * as UserActions from './redux/actions/userAction';
import Products from './pages/astro-mall/products';
import ProductDetails from './pages/astro-mall/products/product-details';
import Cart from './pages/astro-mall/cart';
import NotFound from './components/features/NotFound';
import PrivacyPolicy from './pages/privacy-policy';
import TermsAndConditions from './pages/terms-and-conditions';

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userCustomerDataById, userAstrologerDataById } = useSelector(state => state?.userReducer);
  const [notification, setNotification] = useState(null);
  // console.log("Foreground Notification ::: ", notification);

  useEffect(() => {
    generateTokenByRequestPermission();

    // Listen for foreground messages
    onMessageListener(setNotification, navigate);

    SocketService.initializeSocket(dispatch, navigate);

  }, [dispatch, navigate]);

  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    const current_user_id = localStorage.getItem('current_user_id');

    if (user_type == 'customer') {
      dispatch(UserActions.getUserCustomerById({ customerId: current_user_id }))
    }

    if (user_type == 'astrologer') {
      dispatch(UserActions.getUserAstrologerById({ astrologerId: current_user_id }))
    }
  }, []);

  return (
    <>
      <Header />
      <Routes>
        <Route path='*' element={<NotFound />} />
        <Route path='/' element={<LandingPage />} />
        {userCustomerDataById && <Route path='/chat-with-astrologer' element={<ChatWithAstrologer />} />}
        {userCustomerDataById && <Route path='/chat-with-astrologer/:name' element={<SingleAstrologer />} />}
        <Route path='/chat' element={<Chat />} />
        <Route path='/chat/intake-details/:profileId' element={<IntakeDetails />} />
        <Route path='/chat/astrologer-accept-reject' element={<AstrologerAcceptReject />} />
        <Route path='/chat/customer-accept-reject' element={<CustomerAcceptReject />} />
        <Route path='/price-list' element={<PriceList />} />
        <Route path='/payment-details' element={<PaymentDetails />} />
        <Route path='/my-wallet' element={<MyWallet />} />
        <Route path='/payment-logs' element={<PaymentLog />} />

        {/* Astro Mall */}
        <Route path='/astro-mall' element={<AstroMall />} />
        <Route path='/astro-mall/products' element={<Products />} />
        <Route path='/astro-mall/products/:name' element={<ProductDetails />} />
        <Route path='/cart' element={<Cart />} />

        <Route path='/our-consultant' element={<OurConsultant />} />
        <Route path='/product-payment' element={<PaymentOption />} />
        <Route path='/add-address' element={<AddAddress />} />
        <Route path='/deliver-to' element={<Deliver />} />

        <Route path="/blog" element={<Blogs />}>
          <Route path='*' element={<div style={{ minHeight: "100vh", minWidth: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>Page Not Found</div>} />
          <Route path='home' element={<Home />} />
        </Route>
        <Route path="/single-blog-page" element={<SingleBlogPage />} />
        <Route path="/single-blog" element={<SingleBlog />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

      </Routes>
      <Footer />

      <ChatInvoiceModal />
      <RatingModal />
      <ToastContainer />
    </>
  )
}

export default App;