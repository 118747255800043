import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, onValue, serverTimestamp, set } from "firebase/database";

import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDMirwB_ms0XHhiOU1XaVZ5SLgd-ylxB7M",
  authDomain: "astroremedy-90cb0.firebaseapp.com",
  databaseURL: "https://astroremedy-90cb0-default-rtdb.firebaseio.com",
  projectId: "astroremedy-90cb0",
  storageBucket: "astroremedy-90cb0.appspot.com",
  messagingSenderId: "395034377495",
  appId: "1:395034377495:web:c2311de2d8982ea31dcb52",
};

//! Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

//! Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

const generateTokenByRequestPermission = async () => {
  try {
    const permission = await Notification.requestPermission()

    if (permission === 'granted') {
      const fcm_token = await getToken(messaging, { vapidKey: "BPzYOOxSV5DzLMOgqAVATuSETsXmvce1GIOJo1mvuP9JmcGg-8U1yWSMB5ECOjWpEFEXl1VBPdqINUIGJHHFKH0" });
      console.log('FCM Token', fcm_token);
      localStorage.setItem('fcm_token', fcm_token);
      return fcm_token;
    } else if (permission === 'denied') {
    }
  } catch (error) {
    console.log(error)
  }
};

// Handle foreground messages
const onMessageListener = (setNotification, navigate) => {
  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);

    setNotification({
      title: payload.data.title,
      body: payload.data,
    });

    const notificationTitle = payload.data.title;
    const notificationOptions = {
      body: payload.data.title,
      // icon: payload.notification.image,
      data: payload.data // Pass the additional data
    };

    const notificationData = notificationOptions.data;
    console.log("NotificationData :: ", notificationData);

    if (Notification.permission === 'granted' && notificationData && notificationData?.sent_to) {
      const notification = new Notification(notificationTitle, notificationOptions);

      notification.onclick = (event) => {
        event.preventDefault(); // Prevent the browser from focusing the Notification's tab

        let url;
        switch (notificationData?.sent_to) {
          case 'astrologer':
            console.log('For Astrologer', notificationData?.sent_to)
            url = `/chat/astrologer-accept-reject?user_id=${notificationData.user_id}&astroID=${notificationData.astroID}&chatId=${notificationData.chatId}&customerName=${notificationData.customerName}&invoiceId=${notificationData.invoiceId}&priority=${notificationData.priority}&profileId=${notificationData.profileId}&type=${notificationData.type}&wallet_balance=${notificationData.wallet_balance}`;
            break;

          case 'customer':
            console.log('For Customer', notificationData?.sent_to)
            url = `/chat/customer-accept-reject?user_id=${notificationData.user_id}&astroID=${notificationData.astroID}&chatId=${notificationData.chatId}&astrologerName=${notificationData.astrologerName}&chatPrice=${notificationData.chatPrice}&priority=${notificationData.priority}&type=${notificationData.type}`;
            break;

          default:
            break
        }

        console.log("Url ::: ", url);
        navigate(url, '_blank');
      };

    } else {
      new Notification(notificationTitle, notificationOptions);
    }
  });
};

export { database, ref, push, onValue, serverTimestamp, set, messaging, generateTokenByRequestPermission, onMessageListener };