import React, { Component, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AstrologerCard from "../../cards/astrologerCard";
import bracelet from '../../../assets/images/slider/bracelet.jpeg';
import havan from '../../../assets/images/slider/havan.png';
import havan2 from '../../../assets/images/slider/havan2.jpg';
import heart from '../../../assets/images/slider/heart.jpeg';
import { useDispatch, useSelector } from "react-redux";
import * as AstrologerActions from '../../../redux/actions/astrologerAction';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

const CustomLeftArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-primary text-white p-2 rounded-full z-50"
    style={{ left: '-0px' }} // Adjusted left positioning
  >
    &#8592;
  </button>
);

const CustomRightArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-primary text-white p-2 rounded-full z-50"
    style={{ right: '-0px' }} // Adjusted right positioning
  >
    &#8594;
  </button>
);

const AstroSlider = () => {
  const dispatch = useDispatch();
  const { astrologerData } = useSelector(state => state?.astrologerReducer);
  console.log('Astrologer Data ::: ', astrologerData);

  const AstroData = [
    {
      img: bracelet,
      views: 120,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      name: "John Doe",
      time: "2 hours ago"
    },
    {
      img: havan,
      views: 98,
      description: "Pellentesque habitant morbi tristique senectus et netus.",
      name: "Jane Smith",
      time: "1 day ago"
    },
    {
      img: havan2,
      views: 256,
      description: "Sed do eiusmod tempor incididunt ut labore et dolore.",
      name: "Alice Johnson",
      time: "3 days ago"
    },
    {
      img: heart,
      views: 75,
      description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
      name: "Bob Brown",
      time: "1 week ago"
    }
  ];

  useEffect(() => {
    //! Dispatching API For Getting Astrologer 
    dispatch(AstrologerActions.getAstrologer());
  }, []);

  return (
    <div className="relative">
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlay={false}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={"desktop"}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item"
        arrows={true}
        renderButtonGroupOutside={true}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        {astrologerData.map((value, index) => (
          <div key={index} className="carousel-item p-2">
            <AstrologerCard data={value} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default AstroSlider;
