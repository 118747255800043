import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const NotFound = () => {
    const { pathname } = useLocation();
    useEffect(() => { window.scrollTo(0, 0) }, [pathname]);

    return (
        <div className="flex items-center justify-center min-h-screen max-md:min-h-96">
            <div className="flex flex-col gap-8 items-center">
                <h1 className="text-9xl max-md:text-7xl font-extrabold animate-pulse text-primary">404</h1>
                <p className="text-2xl max-md:text-lg text-primary animate-bounce">Page Not Found</p>
                <Link to="/" className="px-8 py-3 bg-white text-primary font-semibold rounded-lg shadow-md hover:bg-primary hover:text-white transition duration-300 ease-in-out">Go Home</Link>
            </div>
        </div>
    );
};

export default NotFound;