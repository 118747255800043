import React from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import './index.scss'
import SingleBlog from './single-blog';
import RecentBlog from './recent-blog';
import call from '../../assets/images/button/call.png'
import chat from '../../assets/images/button/comment.png'
import OurAstrologers from '../our-astrologers';
import cardimg from '../../assets/images/cardBlog/cardimg.jpg'
import whatsaap from '../../assets/images/cardBlog/whatsapp.png'
import facebook from '../../assets/images/cardBlog/facebook.png'
import twitter from '../../assets/images/cardBlog/twitter.png'
import linkedin from '../../assets/images/cardBlog/linkedin.png'
import { connect } from "react-redux";
import * as BlogActions from "../../redux/actions/blogActions.js"
import { api_urls } from '../../utils/api-urls/index.js';
import moment from "moment";
import RelatedBlog from './related-blog/index.js';
import TopHeaderSection from '../../components/common/TopHeaderSection.jsx';


const SingleBlogPage = () => {
    const location = useLocation();
    const product = location.state && location.state.astroBlogData;

    return (
        <>
            <TopHeaderSection title={'Blog'} />

            <div className='flex  min-[100vh] px-[80px]  max-md:px-[20px]'>
                <div className='flex-1'>
                    <section className='px-[20px] py-[50px] max-md:px-[20px]'>
                        <img src={api_urls + 'uploads/' + product.image} className="w-[100%]  h-[500px] rounded-lg" />
                        <main className=" flex flex-wrap gap-3 py-[20px]">
                            <div>
                                <img src={whatsaap} className=" w-[35px] h-[35px]  hover:opacity-50" />
                            </div>
                            <div>
                                <img src={facebook} className=" w-[35px] h-[35px] hover:opacity-50" />
                            </div>
                            <div>
                                <img src={twitter} className=" w-[35px] h-[35px] hover:opacity-50" />
                            </div>
                            <div>
                                <img src={linkedin} className=" w-[35px] h-[35px] hover:opacity-50" />
                            </div>

                        </main>
                        <div>
                            {/* {discription} */}
                            <p className="text-gray-500  text-lg pb-[10px]" >
                                <div dangerouslySetInnerHTML={{ __html: product?.description }}></div>
                            </p>
                            <h1 className="text-[#212529]  py-[10px] text-2xl"> 1. Sagittarius: The Ultimate Explorer </h1>
                            <p className="text-gray-500  text-lg" >
                                Sagittarius, represented by the archer, is known for their love of freedom and exploration. Ruled by Jupiter, the planet of expansion and adventure, Sagittarians are natural-born travelers. They crave new experiences, cultural immersion, and the thrill of discovering the unknown. Their optimistic and adventurous nature often leads them to far-flung destinations, where they can satisfy their wanderlust and expand their horizons.
                            </p>

                            <div className="text-gray-600 font-semibold py-[30px] text-xl" >
                                <p> Want To Know About You Love Life?  <span className="text-[#BBAB21] cursor-pointer" > <NavLink to=""> Talk To our astrologer </NavLink></span></p>
                            </div>

                            <h1 className="text-[#212529]  py-[10px] text-2xl"> 2. Gemini: The Curious Wanderer</h1>
                            <p className="text-gray-500  text-lg" >
                                Gemini, symbolized by the twins, is ruled by Mercury, the planet of communication and curiosity. Geminis are known for their restless minds and insatiable desire to learn and explore. They thrive on change and diversity, making them eager travelers who seek intellectual stimulation and new perspectives. Geminis are likely to hop from one destination to another, soaking up knowledge and connecting with people from all walks of life.
                            </p>

                            <div className="text-gray-600 font-semibold py-[30px] text-xl" >
                                <p> Also Read:  <span className="text-[#BBAB21] cursor-pointer" > <NavLink to=""> 4 Zodiac Signs Who Fall in Love Fast </NavLink></span></p>
                            </div>

                            <h1 className="text-[#212529]  py-[10px] text-2xl"> 3. Gemini: The Curious Wanderer</h1>
                            <p className="text-gray-500  text-lg" >
                                Gemini, symbolized by the twins, is ruled by Mercury, the planet of communication and curiosity. Geminis are known for their restless minds and insatiable desire to learn and explore. They thrive on change and diversity, making them eager travelers who seek intellectual stimulation and new perspectives. Geminis are likely to hop from one destination to another, soaking up knowledge and connecting with people from all walks of life.
                            </p>

                            <div className="text-gray-600 font-semibold py-[30px] text-xl" >
                                <p> Want To Know About You Love Life?  <span className="text-[#BBAB21] cursor-pointer" > <NavLink to=""> Talk To our astrologer </NavLink></span></p>
                            </div>

                            <h1 className="text-[#212529]  py-[10px] text-2xl"> 4. Gemini: The Curious Wanderer</h1>
                            <p className="text-gray-500  text-lg" >
                                Gemini, symbolized by the twins, is ruled by Mercury, the planet of communication and curiosity. Geminis are known for their restless minds and insatiable desire to learn and explore. They thrive on change and diversity, making them eager travelers who seek intellectual stimulation and new perspectives. Geminis are likely to hop from one destination to another, soaking up knowledge and connecting with people from all walks of life.
                            </p>

                            <div className="text-gray-600 font-semibold py-[30px] text-xl pb-[20px]" >
                                <p>For interesting astrology videos, follow us on   <span className="text-[#BBAB21] cursor-pointer" > <NavLink to=""> Instagram. </NavLink></span></p>
                            </div>

                            <main className=" flex flex-wrap gap-3 pb-[10px]">
                                <div>
                                    <img src={whatsaap} className=" w-[35px] h-[35px]  hover:opacity-50" />
                                </div>
                                <div>
                                    <img src={facebook} className=" w-[35px] h-[35px] hover:opacity-50" />
                                </div>
                                <div>
                                    <img src={twitter} className=" w-[35px] h-[35px] hover:opacity-50" />
                                </div>
                                <div>
                                    <img src={linkedin} className=" w-[35px] h-[35px] hover:opacity-50" />
                                </div>

                            </main>
                            <p className=" pt-[10px]" > Posted On -{moment(product?.createdAt).format("DD-MM-YY")}| Posted By - {product?.created_by} | Read By -  63,313  </p>


                        </div>

                    </section>

                </div>


                <div className="side-bar min-h-screen min-w-[350px] flex flex-col gap-10 p-10 overflow-y-scroll relative transition-all duration-1000 ease-linear">

                    <NavLink to="/blog" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}>Home</NavLink>
                    <NavLink to="/blog" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}>Success</NavLink>
                    <NavLink to="/blog" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}>Marriage</NavLink>
                    <NavLink to="/blog" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}>Life</NavLink>
                    <NavLink to="/blog" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}>Financial</NavLink>
                    <NavLink to="/blog" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}>Vastu</NavLink>
                    <NavLink to="/blog" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}>Education</NavLink>
                    <NavLink to="/blog" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}>Business</NavLink>
                    <NavLink to="/blog" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}>Festival</NavLink>
                    <NavLink to="/blog" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}>Relationship</NavLink>

                </div>
            </div >

            <div className='px-[80px]  max-md:px-[20px]'>

                <div className='py-[20px]  my-[40px] bg-[#F0DF20] rounded-md text-center ' >
                    <h1 className='text-[#4E4E4E] text-2xl '> Connect with an Astrologer on Call or Chat for more personalised detailed predictions. </h1>
                    <main className='flex flex-wrap justify-between  px-[150px] pt-[20px]'>

                        <div className='px-12 py-4 bg-white rounded-full  flex flex-wrap gap-2 items-center hover:bg-black hover:text-white transition-colors duration-300 ' style={{ boxShadow: "0 0 2px #bdb5b5" }}  >
                            <img src={call} alt="call icon " className='w-[20px]' />
                            <p> Talk to Astrologer </p>
                        </div>
                        <div className='px-12 py-4 bg-white rounded-full  flex flex-wrap gap-2 items-center hover:bg-black hover:text-white transition-color duration-300' style={{ boxShadow: "0 0 2px #bdb5b5" }}  >
                            <img src={chat} alt="chat icon " className='w-[20px]' />
                            <p> Chat with Astrologer </p>
                        </div>

                    </main>
                </div>

                {/* <div className='pb-[30px]'>
                    <h1 className='text-black text-3xl text-center'> RELATED BLOGS </h1>
                    <RelatedBlog  />
                </div> */}

                <div className='pb-[30px]'>
                    <h1 className='text-black text-3xl text-center'> RECENT BLOGS</h1>
                    <RecentBlog />
                </div>

                <div className='pb-[30px]'>
                    <div className='my-[20px]'>
                        <h1 className='text-gray-600 text-3xl font-bold text-center my-[20px]'> OUR ASTROLOGERS</h1>
                        <p className='text-gray text-center' > ____21,000+ Best Astrologers from India for Online Consultation____ </p>
                    </div>
                    <OurAstrologers />
                </div>


            </div>


        </>
    );

}


export default SingleBlogPage;