import React from 'react'
import { Link } from 'react-router-dom';
import AppStore from '../../assets/images/footer/app-store.png';
import PlayStore from '../../assets/images/footer/google-play.png'
import fb from '../../assets/images/footer/fb.svg'
import insta from '../../assets/images/footer/insta.svg'
import linkedin from '../../assets/images/footer/linkedin.svg'
import pinterest from '../../assets/images/footer/pinterest.svg'
import twitter from '../../assets/images/footer/twitter.svg'
import youtube from '../../assets/images/footer/youtube.svg'
import footerbg from '../../assets/images/footer/footerbg.jpg'
import aboutBg from '../../assets/images/backgroundImgs/Rectangle_42398.jpg';

const Footer = () => {
    return (
        <footer >
            <section className='px-[100px] py-[30px] text-white max-md:px-[20px]' style={{ backgroundImage: `url(${aboutBg})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundAttachment: 'fixed' }}>
                <article>
                    <main className="flex flex-wrap gap-0 justify-between mb-8   ">

                        <main className="flex-1 flex flex-col gap-y-1 max-md:flex-[50%]">
                            <b className='my-6 inline-block text-[#FED18A]'> Get Advice On </b>
                            <main className="flex flex-col gap-3 items-start">
                                <div className="hover:border-b-[1px]">
                                    <Link to="/terms-and-conditions" className="hover:text-[#FFC81D]">Terms & Conditions</Link>
                                </div>
                                <div className="hover:border-b-[1px]">
                                    <Link to="/privacy-policy" className="hover:text-[#FFC81D]">Privay Policy</Link>
                                </div>
                                <div className="hover:border-b-[1px]">
                                    <div className="hover:text-[#FFC81D]">About us</div>
                                </div>
                                <div className="hover:border-b-[1px]">
                                    <Link to="/blog" className="hover:text-[#FFC81D]">Blog</Link>
                                </div>

                                <div className="hover:border-b-[1px]">
                                    <Link to="/support" className="hover:text-[#FFC81D]">Support</Link>
                                </div>
                            </main>
                        </main>

                        <main className="flex-[20%] flex flex-col gap-y-1 max-md:flex-[100%] max-md: items-center">
                            <b className='my-6 inline-block text-[#FED18A]'> Download Our Apps</b>
                            <img src={PlayStore} className='w-44 cursor-pointer max-md:w-32' />
                            <img src={AppStore} className='w-44 cursor-pointer max-md:w-32' />
                            <main className="flex gap-3 mt-4">
                                <img src={fb} className='w-12 cursor-pointer max-md:w-7' />
                                <img src={insta} className='w-12 cursor-pointer max-md:w-7' />
                                <img src={linkedin} className='w-12 cursor-pointer max-md:w-7' />
                                <img src={twitter} className='w-12 cursor-pointer max-md:w-7' />
                                <img src={youtube} className='w-12 cursor-pointer max-md:w-7' />
                            </main>
                        </main>

                    </main>
                </article>
            </section>

            <div className='px-[100px] py-[10px] bg-[#FED1A7] text-black flex justify-center items-center text-center max-md:px-[10px] '>
                Copyright © 2020-2024 Innovana Astro Services Ltd. All Rights Reserved
            </div>
        </footer>
    )
}

export default Footer;