import React from 'react';
import moment from 'moment';

const BirthDetail = ({ data }) => {
    console.log("BirthDetail ::: ", data);
    const intake_data = JSON.parse(localStorage.getItem('intake_data'));

    return (
        <div className="px-7 py-5 flex flex-col gap-4 bg-white rounded-lg">
            <div className="flex items-start">
                <div className="basis-[40%] font-medium text-gray-700">Name</div>
                <div className="basis-[60%] text-gray-900">{intake_data?.firstName} {intake_data?.lastName}</div>
            </div>
            <div className="flex items-start">
                <div className="basis-[40%] font-medium text-gray-700">Date</div>
                <div className="basis-[60%] text-gray-900">{moment.utc(intake_data?.dateOfBirth).format('MMM Do YYYY')}</div>
            </div>
            <div className="flex items-start">
                <div className="basis-[40%] font-medium text-gray-700">Time</div>
                <div className="basis-[60%] text-gray-900">{moment.utc(intake_data?.timeOfBirth).format('HH:mm A')}</div>
            </div>
            <div className="flex items-start">
                <div className="basis-[40%] font-medium text-gray-700">Place</div>
                <div className="basis-[60%] text-gray-900">{intake_data?.placeOfBirth}</div>
            </div>
            <div className="flex items-start">
                <div className="basis-[40%] font-medium text-gray-700">Latitude</div>
                <div className="basis-[60%] text-gray-900">{data?.kundliBirthDetailData?.latitude}</div>
            </div>
            <div className="flex items-start">
                <div className="basis-[40%] font-medium text-gray-700">Longitude</div>
                <div className="basis-[60%] text-gray-900">{data?.kundliBirthDetailData?.longitude}</div>
            </div>
            <div className="flex items-start">
                <div className="basis-[40%] font-medium text-gray-700">Time Zone</div>
                <div className="basis-[60%] text-gray-900">+05.30</div>
            </div>
            <div className="flex items-start">
                <div className="basis-[40%] font-medium text-gray-700">Ayanamsha</div>
                <div className="basis-[60%] text-gray-900">{data?.kundliBirthDetailData?.ayanamsha}</div>
            </div>
            <div className="flex items-start">
                <div className="basis-[40%] font-medium text-gray-700">Sunrise</div>
                <div className="basis-[60%] text-gray-900">{data?.kundliBirthDetailData?.sunrise}</div>
            </div>
            <div className="flex items-start">
                <div className="basis-[40%] font-medium text-gray-700">Sunset</div>
                <div className="basis-[60%] text-gray-900">{data?.kundliBirthDetailData?.sunset}</div>
            </div>
        </div>
    )
}

export default BirthDetail;