import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Modal from 'react-modal';
import ReactStars from 'react-stars';
import { toast } from 'react-toastify';
import Profile from '../../../assets/images/logo/profile.jpg';
import RadioButton from '../../../components/button/RadioButton';
import PorgressCard from '../../../components/cards/PorgressCard';
import { CrossSvg, StarSvg, VerifySvg } from '../../../assets/svg';
import { api_urls } from '../../../utils/api-urls';
import { ProgressData, Similar } from '../../../utils/dbs';
import { DateDifference, IndianRupee, ParseDateTime, YYYYMMDD } from '../../../utils/common-function';
import { linked_profile } from '../../../utils/api-routes';
import * as ChatActions from '../../../redux/actions/chatAction';
import * as AstrologerActions from '../../../redux/actions/astrologerAction';
import Swal from 'sweetalert2';
import AppStore from '../../../assets/images/footer/app-store.png';
import PlayStore from '../../../assets/images/footer/google-play.png';
import DownloadApp from '../../../components/cards/DownloadApp';
import { Color } from '../../../assets/colors';
import TopHeaderSection from '../../../components/common/TopHeaderSection';

Modal.setAppElement('#root');
const customStyles = { content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', padding: 0, borderRadius: "5px", minWidth: "400px", maxWidth: "900px", maxHeight: "500px" }, };

const SingleAstrologer = () => {
    const { pathname } = useLocation();
    useEffect(() => { window.scrollTo(0, 0) }, [pathname]);

    const navigate = useNavigate();
    const location = useLocation();
    const stateData = location?.state?.stateData;
    const astrologerId = stateData?._id;

    const dispatch = useDispatch();
    const { astrologerDataById, astrologerReviewDataById } = useSelector(state => state?.astrologerReducer);
    const reversedAstrologerReviewData = [...astrologerReviewDataById].reverse();
    const { linkedProfileData } = useSelector(state => state?.chatReducer);
    const { userCustomerDataById } = useSelector(state => state?.userReducer);

    //! Call Modal 
    const [callModal, setCallModal] = useState(false);
    const handleOpenCallModal = () => setCallModal(true);
    const handleCloseCallModal = () => setCallModal(false);

    // TODO : Linked profile
    const [linkedProfileModal, setLinkedProfileModal] = useState(false);
    const handleOpenLinkedProfileModal = () => setLinkedProfileModal(true);
    const handleCloseLinkedProfileModal = () => setLinkedProfileModal(false);
    const [selectedLinkedProfileData, setSelectedLinkedProfileData] = useState({});

    //* Handle Select : Linked Profile Data
    const handleSelectedLinkedProfileData = (data) => {
        setChatIntakeDetail({
            isNewProfile: false, first_name: data?.firstName, last_name: data?.lastName, gender: data?.gender, date_of_birth: moment(data?.dateOfBirth).format('YYYY-MM-DD'), time_of_birth: moment(data?.timeOfBirth).format('HH:mm'), place_of_birth: data?.placeOfBirth, marital_status: data?.maritalStatus, type_of_concern: data?.topic_of_concern, latitude: data?.latitude, longitude: data?.longitude, description: data?.description
        })
        setSelectedLinkedProfileData(data);
    };

    // TODO : Chat Intake Form 
    const [chatIntakeFormModal, setChatIntakeFormModal] = useState(false);
    const handleOpenChatIntakeFormModal = () => setChatIntakeFormModal(true);
    const handleCloseChatIntakeFormModal = () => {
        setChatIntakeFormModal(false);
        setChatIntakeDetail({
            isNewProfile: true, first_name: '', last_name: '', gender: '', date_of_birth: '', time_of_birth: '', place_of_birth: '', marital_status: '', type_of_concern: '', latitude: '20.5937', longitude: '78.9629', description: ''
        })
        setSelectedLinkedProfileData({})
    };

    const [chatIntakeDetail, setChatIntakeDetail] = useState({
        isNewProfile: true, first_name: '', last_name: '', gender: '', date_of_birth: '', time_of_birth: '', place_of_birth: '', marital_status: '', type_of_concern: '', latitude: '20.5937', longitude: '78.9629', description: ''
    });

    //* Handle Input : Chat Intake Form Data
    const handleChatIntakeDetail = (e) => {
        const { name, value } = e.target;
        setChatIntakeDetail({ ...chatIntakeDetail, [name]: value })
    };

    //* Handle Validation For Intake Form Data
    const handleValidation = () => {
        const { first_name, last_name, gender, date_of_birth, time_of_birth, place_of_birth, marital_status, type_of_concern, description, latitude, longitude } = chatIntakeDetail;

        let isValid = true;

        if (!first_name) {
            toast.error('Please Enter First Name')
            return isValid = false
        }
        if (!last_name) {
            toast.error('Please Enter First')
            return isValid = false
        }
        if (!gender) {
            toast.error('Please Select Gender')
            return isValid = false
        }
        if (!date_of_birth) {
            toast.error('Please Enter Date Of Birth')
            return isValid = false
        }
        if (!time_of_birth) {
            toast.error('Please Enter Time Of Birth')
            return isValid = false
        }
        if (!place_of_birth) {
            toast.error('Please Enter Place Of Birth')
            return isValid = false
        }
        if (!marital_status) {
            toast.error('Please Select Marital Status')
            return isValid = false
        }
        if (!type_of_concern) {
            toast.error('Please Select Type Of Concern')
            return isValid = false
        }
        if (!description) {
            toast.error('Please Enter Description')
            return isValid = false
        }

        return isValid;
    }

    //! Handle Submit : Chat Intake Form Data
    const handleSubmitChatIntakeForm = async () => {
        if (handleValidation()) {
            console.log({ ...chatIntakeDetail });
            const { isNewProfile, first_name, last_name, gender, date_of_birth, time_of_birth, place_of_birth, marital_status, type_of_concern, description, latitude, longitude } = chatIntakeDetail;

            const payload = {
                isNewProfile: isNewProfile,
                profileData: { firstName: first_name, lastName: last_name, gender: gender, dateOfBirth: date_of_birth, timeOfBirth: ParseDateTime(date_of_birth, time_of_birth), placeOfBirth: place_of_birth, maritalStatus: marital_status, topic_of_concern: type_of_concern, latitude, longitude, description },
                selectedProfileId: selectedLinkedProfileData?._id ?? null,
                chatPrice: astrologerDataById?.chat_price,
                astrologerId: astrologerId,
                type: astrologerDataById?.type,
                onComplete: () => {
                    handleCloseChatIntakeFormModal();
                    navigate('/chat-with-astrologer');
                }
            }
            //! Dispatch Chat Request : Send By Customer 
            dispatch(ChatActions.chatRequestSendByCustomer(payload));
        }
    };

    //! Location
    const [query, setQuery] = useState('');
    const [locationData, setLocationData] = useState(null);
    const [debouncedQuery, setDebouncedQuery] = useState(query);

    // Debounce the query to avoid excessive API calls
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(query);
        }, 500); // Adjust the debounce delay as needed

        return () => {
            clearTimeout(handler);
        };
    }, [query]);

    useEffect(() => {
        if (debouncedQuery) {
            handleSearch(debouncedQuery);
        } else {
            setLocationData(null);
        }
    }, [debouncedQuery]);

    const handleSearch = async (searchQuery) => {
        const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(searchQuery)}&key=${apiKey}`;

        try {
            const response = await axios.get(url);
            console.log("map Response ::: ", response);

            if (response.data.results.length > 0) {
                const location = response.data.results[0];
                console.log("location Response ::: ", location);
                setLocationData({
                    name: location.formatted_address,
                    latitude: location.geometry.location.lat,
                    longitude: location.geometry.location.lng,
                });
            } else {
                setLocationData(null);
            }
        } catch (error) {
            console.error('Error fetching location data:', error);
            setLocationData(null);
        }
    };

    useEffect(() => {
        //! Dispatching API For Getting Single Astrologer
        dispatch(ChatActions.getLinkedProfileForChat({ customerId: localStorage.getItem('current_user_id') }))

        //! Dispatching API For Getting Single Astrologer
        dispatch(AstrologerActions.getAstrologerById({ astrologerId }))

        //! Dispatching API For Getting Single Astrologer Review
        dispatch(AstrologerActions.getAstrologerReviewById({ astrologer_id: astrologerId }))
    }, []);

    return (
        <>
            <TopHeaderSection title={astrologerDataById?.astrologerName} />

            <section className='px-[100px] py-7 max-sm:px-[20px]'>
                <article className='flex flex-col gap-5'>
                    <main className='border rounded-lg p-6 flex flex-col gap-y-14'>
                        <main className='flex flex-wrap gap-x-7 gap-y-5'>
                            <div className='flex flex-col gap-y-4'>
                                <div><img src={api_urls + astrologerDataById?.id_proof_image} className='h-52 w-52 rounded-[50%]' /></div>
                                <div className='flex justify-center'><div className='bg-primary text-white font-semibold py-1 px-5 rounded-md'>Follow</div></div>
                            </div>

                            <div className='flex flex-col gap-y-3 text-grey text-lg'>
                                <div className='text-2xl font-semibold text-black flex items-center gap-3'>{astrologerDataById?.astrologerName}  {astrologerDataById?.chat_status == "online" ? <VerifySvg color='green' /> : <VerifySvg color='red' />} </div>


                                <div>Vedic, Life, Coach, Fac...</div>
                                <div>{astrologerDataById?.language?.length > 0 ? astrologerDataById?.language?.join(' , ') : "Hindi"}</div>
                                <div>Exp: {astrologerDataById?.experience} Years</div>
                                <div className='text-grey font-semibold'>{IndianRupee(astrologerDataById?.chat_price)}</div>
                                {/* <div className='flex  gap-x-10'>
                                    <div className='text-grey font-semibold'>22K <span className='font-normal'>mins</span></div>
                                    <div className='text-grey font-semibold'>9K <span className='font-normal'>mins</span></div>
                                </div> */}

                                <div className='flex flex-wrap gap-x-3 gap-y-3 mt-4 text-[15px]'>
                                    <button onClick={async () => {
                                        if (Number(userCustomerDataById?.wallet_balance) < Number(astrologerDataById?.chat_price) * 5) {
                                            console.log(Number(userCustomerDataById?.wallet_balance));
                                            console.log(Number(astrologerDataById?.chat_price) * 5);
                                            const result = await Swal.fire({
                                                icon: "warning", title: "Warning", text: "Please Recharge Your Wallet", showConfirmButton: true, timer: 20000,
                                                confirmButtonText: "Recharge", confirmButtonColor: Color.primary, cancelButtonText: "Cancel", showCancelButton: true, cancelButtonColor: Color.darkgrey
                                            });
                                            console.log('result', result)
                                            if (result.isConfirmed) {
                                                navigate('/price-list')
                                            }
                                        } else {
                                            handleOpenChatIntakeFormModal()
                                        }
                                    }} disabled={astrologerDataById?.chat_status != "online"} className={`flex flex-col justify-center items-center border border-primary text-primary px-24 py-2 rounded-[50px] ${astrologerDataById?.chat_status != "online" && 'cursor-not-allowed'}`}>
                                        <div>Start Chat</div>
                                    </button>
                                    <div onClick={() => handleOpenCallModal()} className='flex flex-col justify-center items-center border border-primary text-primary px-24 py-2 rounded-[50px] cursor-pointer'>
                                        <div>Start Call</div>
                                    </div>
                                </div>
                            </div>
                        </main>

                        <div>
                            <div className='text-center font-semibold text-xl mb-3'>About me</div>
                            <div className='text-justify text-grey'>{astrologerDataById?.long_bio}</div>
                        </div>
                    </main>

                    <main className='flex flex-wrap gap-5 items-start'>
                        <div className='border rounded-lg basis-[45%] max-sm:basis-[100%] p-5'>
                            <div className='font-semibold'>Ratings & Reviews</div>
                            <main className='flex gap-5'>
                                <div className='flex-grow basis-[40%] flex flex-col items-center justify-center gap-2'>
                                    <div className='text-5xl'>{astrologerDataById?.rating?.toFixed(2)}</div>
                                    <ReactStars count={5} edit={false} value={astrologerDataById?.rating} size={24} color2={'#ffd700'} />
                                    <div className='text-red-700 font-semibold'>New!</div>
                                </div>
                                {/* <div className='basis-[60%] flex flex-col gap-2'>
                                    {ProgressData.map((value, index) => (
                                        <div key={index} className='flex gap-5 items-center'>
                                            <div>{ProgressData.length - index}</div> <PorgressCard width={'50%'} />
                                        </div>
                                    ))}
                                </div> */}
                            </main>
                        </div>

                        <div className='flex-grow basis-[45%] max-sm:basis-[100%] flex flex-col gap-5'>
                            {/* <div className='border rounded-lg p-5'>
                                <div className='font-semibold'>Check Similar Consultants</div>
                                <main className='flex flex-wrap justify-between mt-4'>
                                    {Similar.map((value, index) => (
                                        <main key={index} className='basis-[30%] border rounded-lg flex flex-col items-center gap-1 px-5 py-2'>
                                            <div><img src={Profile} className='h-20 w-20 rounded-[50%]' /></div>
                                            <div>{value?.name}</div>
                                            <div className='text-grey text-sm'>{IndianRupee(30)}/min</div>
                                        </main>
                                    ))}
                                </main>
                            </div> */}

                            <div className='border rounded-lg p-5 flex flex-col gap-4'>
                                <div className='font-semibold'>User Review</div>
                                {/* <main className='border rounded-lg p-5 py-3 flex  gap-2 justify-between'>
                                    <div>Sort by</div>
                                    <div className='flex gap-5'>
                                        <div className='flex items-baseline gap-2'>
                                            <input type="radio" id="most-helpful" name="review-radio" value="Most helpful" />
                                            <label htmlFor='most-helpful'>Most helpful</label>
                                        </div>
                                        <div className='flex items-baseline gap-2'>
                                            <input type="radio" id="most-recent" name="review-radio" value="Most recent" />
                                            <label htmlFor='most-recent'>Most recent</label>
                                        </div>
                                    </div>
                                </main> */}

                                <main className='flex flex-col gap-3'>
                                    {reversedAstrologerReviewData.length > 0 ? reversedAstrologerReviewData.map((value, index) => (
                                        <main key={index} className='border rounded-lg p-5 flex flex-col gap-2' style={{ boxShadow: "0 0 5px #bdb5b5" }}>
                                            <div className='flex gap-4 items-center'>
                                                <div><img src={Profile} className='h-10 w-10 rounded-[50%]' /></div>
                                                <div>{value?.comments}</div>
                                            </div>
                                            <div className='flex gap-0 text-gray-600'><ReactStars count={5} edit={false} value={value?.ratings} size={24} color2={'#ffd700'} /></div>
                                        </main>
                                    )) : <div className='p-10 text-center'>No Data Found</div>}
                                </main>
                            </div>
                        </div>
                    </main>
                </article>
            </section >

            <Modal isOpen={chatIntakeFormModal} className="modal-content" overlayClassName="modal-overlay" closeTimeoutMS={200} >
                <div className='text-center bg-primary text-white py-2 px-5 font-semibold flex justify-between'>
                    <div></div>
                    <div>Chat Intake Form</div>
                    <div onClick={handleCloseChatIntakeFormModal} className='cursor-pointer' ><CrossSvg h='16' w='16' color='#fff' strokeWidth='5' /></div>
                </div>
                <main className='flex flex-col gap-4 p-5'>
                    <div className='text-center'>Help Our Astrologer Know a little bit about you. Your details will kept completely confidentail.</div>

                    <form className='px-5 my-8 flex flex-wrap justify-between gap-6'>
                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>First Name</label>
                            <input name='first_name' value={chatIntakeDetail?.first_name} onChange={handleChatIntakeDetail} type='text' placeholder='First Name' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>
                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>Last Name</label>
                            <input name='last_name' value={chatIntakeDetail?.last_name} onChange={handleChatIntakeDetail} type='text' placeholder='Last Name' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>
                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>Gender</label>
                            <select name="gender" value={chatIntakeDetail?.gender} onChange={handleChatIntakeDetail} id="gender" className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm'>
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>Date of Birth</label>
                            <input name='date_of_birth' value={chatIntakeDetail?.date_of_birth} onChange={handleChatIntakeDetail} type='date' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>
                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>Time of Birth</label>
                            <input name='time_of_birth' value={chatIntakeDetail?.time_of_birth} onChange={handleChatIntakeDetail} type='time' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>
                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>Place of Birth</label>
                            <input name='place_of_birth' value={chatIntakeDetail?.place_of_birth} onChange={handleChatIntakeDetail} type='text' placeholder='Place of Birth' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>
                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>Marital Status</label>
                            <select name="marital_status" value={chatIntakeDetail?.marital_status} onChange={handleChatIntakeDetail} id="marital_status" className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm'>
                                <option value="">Select Marital Status</option>
                                <option value="Married">Married</option>
                                <option value="Unmarried">Unmarried</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div className='basis-[30%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>Type of Concern</label>
                            <select name="type_of_concern" value={chatIntakeDetail?.type_of_concern} onChange={handleChatIntakeDetail} id="type_of_concern" className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm'>
                                <option value="">Select Type of Concern</option>
                                <option value="Career">Career</option>
                                <option value="Business">Business</option>
                            </select>
                        </div>
                        <div className='basis-[100%] flex flex-col gap-1'>
                            <label className='text-grey text-sm'>Description</label>
                            <textarea name='description' rows={5} value={chatIntakeDetail?.description} onChange={handleChatIntakeDetail} placeholder='Description' className='w-[100%] outline-none bg-greybg px-5 py-[10px] rounded-md text-sm' />
                        </div>

                        {/* <input type="text" value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Search for a location" className="border p-2 rounded w-full" /> */}

                    </form>
                    <div onClick={() => handleOpenLinkedProfileModal()} className='text-center text-primary cursor-pointer'>Show Linked Profile</div>
                    <div onClick={handleSubmitChatIntakeForm} className='bg-primary text-center text-white rounded-lg p-2 text-sm cursor-pointer'>Start Chat</div>
                </main>
            </Modal>

            <Modal isOpen={linkedProfileModal} className="modal-content" overlayClassName="modal-overlay" closeTimeoutMS={200} >
                <div className='text-center bg-primary text-white py-2 px-5 font-semibold flex justify-between'>
                    <div></div>
                    <div>Linked Profile</div>
                    <div onClick={handleCloseLinkedProfileModal} className='cursor-pointer' ><CrossSvg h='16' w='16' color='#fff' strokeWidth='5' /></div>
                </div>

                <main className='flex flex-col gap-4 p-5 max-h-[400px] overflow-scroll'>
                    {linkedProfileData?.map((value, index) => (
                        <RadioButton key={index}
                            label={value?.firstName + ' ' + value?.lastName + ' - ' + value?.gender + ' - ' + DateDifference(moment(value?.dateOfBirth).format('YYYY-MM-DD'))}
                            name="custom-radio"
                            value={value?._id}
                            checked={selectedLinkedProfileData?._id === value?._id}
                            onChange={() => handleSelectedLinkedProfileData(value)}
                        />
                    ))}
                </main>
                <div className='py-2 px-5 font-semibold flex justify-center'>
                    <div onClick={() => handleCloseLinkedProfileModal()} className='bg-primary text-white px-10 py-1 rounded-md cursor-pointer'>Select</div>
                </div>
            </Modal>

            {/* Download App */}
            <DownloadApp isOpen={callModal} handleCloseModal={handleCloseCallModal} />
        </>
    )
}

export default SingleAstrologer;