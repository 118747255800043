import * as actionTypes from "../action-types";

const initialState = {
    linkedProfileData: [],
    chatTimerCountDown: 0,
    chatInvoiceData: {},
    chatInvoiceVisibility: false,
    astrologerRatingVisibility: { data: null, ratingVisible: false },
};

const chatReducer = (state = initialState, actions) => {
    const { payload, type } = actions;

    switch (type) {
        case actionTypes.SET_LINKED_PROFILE_FOR_CHAT: {
            return {
                ...state,
                linkedProfileData: payload
            }
        }
        case actionTypes.SET_CHAT_TIMER_COUNTDOWN: {
            return {
                ...state,
                chatTimerCountDown: payload
            }
        }
        case actionTypes.SET_CHAT_INVOICE_DATA: {
            return {
                ...state,
                chatInvoiceData: payload
            }
        }
        case actionTypes.SET_CHAT_INVOICE_VISIBILITY: {
            return {
                ...state,
                chatInvoiceVisibility: payload
            }
        }
        case actionTypes.SET_ASTROLOGER_RATING_VISIBILITY: {
            return {
                ...state,
                astrologerRatingVisibility: payload
            }
        }
        default: {
            return state;
        }
    }
};

export default chatReducer;
