import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import './blog.scss'
import Home from './home';
import * as BlogActions from "../../redux/actions/blogActions.js"
import { connect } from "react-redux";
import TopHeaderSection from '../../components/common/TopHeaderSection.jsx';


const Blogs = ({ astroBlogData, dispatch }) => {
    const { pathname } = useLocation();
    useEffect(() => { window.scrollTo(0, 0) }, [pathname]);
    const navigate = useNavigate()

    useEffect(() => {
        console.log('sdfsdfsdf')
        dispatch(BlogActions.getAstroBlogs(''));
    }, [dispatch]);

    const onClick = (category) => {
        dispatch(BlogActions.getAstroBlogs(category));
    }

    return (
        <>
            <TopHeaderSection title={'Blogs'} />

            <div className='flex min-[100vh] px-[80px]  max-md:px-[20px]'>
                <div className="side-bar min-h-screen min-w-[350px] flex flex-col gap-10 p-10 overflow-y-scroll relative transition-all duration-1000 ease-linear">

                    <div className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}
                        onClick={() => onClick('')}  >
                        Home
                    </div>
                    <div onClick={() => onClick('Health')} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}  >Health</div>
                    <div onClick={() => onClick('Success')} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}  >Success</div>
                    <div onClick={() => onClick('Marriage')} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}  >Marriage</div>
                    <div onClick={() => onClick('Life')} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}  >Life</div>
                    <div onClick={() => onClick('Financial')} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}  >Financial</div>
                    <div onClick={() => onClick('Vastu')} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}  >Vastu</div>
                    <div onClick={() => onClick('Education')} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}  >Education</div>
                    <div onClick={() => onClick('Business')} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}  >Business</div>
                    <div onClick={() => onClick('Festival')} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}  >Festival</div>
                    <div onClick={() => onClick('Relationship')} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "dashboard-active-link" : ""}  >Relationship</div>

                </div>

                <Home />

                {/* <Outlet /> */}
            </div >
        </>
    );

}

const mapStateToProps = (state) => ({
    astroBlogData: state.blogreducer.astroBlogData,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });



export default connect(mapStateToProps, mapDispatchToProps)(Blogs);