import React, { useEffect, useState } from 'react'
import BlogSlider from '../../components/sliders/blogSlider';
import AstroSlider from '../../components/sliders/astrologerSlider';
import ServicesSlider from '../../components/sliders/servicesSlider';
import BannerSlider from '../../components/sliders/bannerSlider';
import whyastro from '../../assets/images/slider/whyastro.jpg'
import TestimonialSliders from '../../components/sliders/testimonialSlider';
import aboutBg from '../../assets/images/backgroundImgs/Rectangle_42398.jpg';
import testbg from '../../assets/images/backgroundImgs/testbg.png';
import aboutbg from '../../assets/images/backgroundImgs/newabout.jpg';
import './landing.css'
import scroll from "../../assets/images/wheel/wheel.png";
import Astromen from "../../assets/images/wheel/astromen.png";

import AnimatedImage from '../../components/animatedImage';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LandingPage = () => {
    const { pathname } = useLocation();
    useEffect(() => { window.scrollTo(0, 0) }, [pathname]);
    const { userCustomerDataById, userAstrologerDataById } = useSelector(state => state?.userReducer);

    const [isVisible, setIsVisible] = useState(false); // Initially false to avoid initial animation

    // Toggle isVisible to trigger animation on component update
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div>
            <section >
                {/* Banner Slider */}
                <div>
                    <BannerSlider />
                    <img src={testbg} className='w-full h-10 relative z-[1] -mt-8' />
                </div>


                {/* OUR SERVICES */}
                <section className='px-[100px] py-10 max-sm:px-[20px]' style={{ backgroundImage: `url(${testbg})`, }}>
                    <h1 className='text-3xl text-center text-primary'> COMPLIMENTARY ASTROLOGY <span style={{ color: `black`, fontWeight: `bold` }}>SERVICES</span> </h1>
                    <ServicesSlider />
                </section>
                <img src={testbg} className='w-full h-10 relative z-[1] -mt-8' style={{ rotate: `180deg`, marginBottom: `-1em` }} />

                {/* About Us Section */}
                <section className="flex flex-wrap gap-[2rem] justify-between px-[40px] py-20 max-sm:px-[20px]" style={{ backgroundImage: `url(${aboutbg})`, }}>
                    <div className="max-md:basis-full md:flex-1 flex flex-col justify-center p-[2rem] text-white text-content">
                        <h1 className="text-white text-3xl text-center mb-[1rem]"> About Us </h1>
                        <p className="text-white max-md:text-justify mt-4 mb-[1rem]">
                            AstroRemedy is your divine guide to unlocking the secrets of your soul’s journey. Rooted in Vedic astrology, we reveal the wisdom hidden in your Kundli, offering insights into your life’s purpose and spiritual path. By blending ancient practices with modern technology, we provide personalized readings, matchmaking, pooja services, and daily horoscopes to help you align with the universe.
                            At AstroRemedy, we believe every soul is part of the cosmic dance. Let us be your spiritual companion, guiding you towards peace, purpose, and harmony with the stars.
                        </p>
                    </div>

                    <div className="image-content group max-md:basis-full md:flex-1 flex justify-center items-center p-[2rem]">
                        <div className="flex flex-col gap-2">
                            <div className='overflow-hidden relative'>
                                <img src={scroll} alt="Image" className="inline-block animate-rotate360" />
                                <img src={Astromen} alt="Image" className="absolute top-5 transition-all duration-500 max-w-[100%] group-hover:scale-105" />
                            </div>
                        </div>
                    </div>
                </section>


                {/* BLOG SECTION */}
                <section className='px-[100px] py-10 max-sm:px-[20px]' style={{ backgroundImage: `url(${testbg})` }}>
                    <h1 className='text-black text-3xl text-center'> RECENT BLOGS</h1>
                    <BlogSlider />
                </section>

                {/* about Section */}
                <section className='px-[40px] py-10 max-sm:px-[20px]' style={{
                    backgroundImage: `url(${aboutBg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundAttachment: 'fixed'
                }}>
                    <div className='flex flex-wrap gap-2 max-sm:flex-col'>
                        <div className="flex-[50] flex flex-col gap-2" onClick={toggleVisibility}>
                            <div key={isVisible ? "visible" : "hidden"} className={`slide-up ${isVisible ? "animate" : ""}`}>
                                <img src={whyastro} alt="Image" className='transform hover:scale-105 transition duration-300' />
                            </div>
                        </div>
                        <div className='flex-[50] flex  flex-col gap-4'>
                            <h1 className='text-white text-3xl text-center'> Why Astrology </h1>
                            <p className="text-white  max-md:text-justify mt-4">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

                                Why do we use it?
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                            </p>
                        </div>
                    </div>
                </section>

                {/* OUR ASTROLOGERS */}
                {userCustomerDataById && <section className='px-[100px] py-10 max-sm:px-[20px] bg-[#FFF3EB]'>
                    <h1 className='text-black text-3xl text-center'> Our Astrologers </h1>
                    <AstroSlider />
                </section>}

                {/* OUR Testimonials */}
                <section className='px-[100px] py-10 max-sm:px-[20px]' style={{ backgroundImage: `url(${testbg})` }}>
                    <h1 className='text-black text-3xl text-center'> Our Testimonials </h1>
                    <TestimonialSliders />
                </section>

            </section>
        </div>
    )
}

export default LandingPage;