import React, { useEffect } from "react";
import BlogCard from "../../../components/cards/BlogCard";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import * as BlogActions from "../../../redux/actions/blogActions.js";
import { connect } from "react-redux";
import cardimg from "../../../assets/images/cardBlog/cardimg.jpg";
import { api_urls } from "../../../utils/api-urls/index.js";
import moment from "moment";
import { getShortDescription } from "../../../utils/common-function/index.js";

const Home = ({ astroBlogData, dispatch }) => {
    console.log(astroBlogData)
    const navigate = useNavigate()

    const handleCardClick = (blogData) => {
        console.log("Handle Click ::: ", blogData);
        navigate("/single-blog-page", {
            state: { astroBlogData: blogData },
        });
    }

    return (
        <main className="flex flex-wrap gap-4 py-10 px-[30px]">
            {astroBlogData && astroBlogData.map(blogData => (
                <div key={blogData.id} className="flex-30">

                    <BlogCard
                        onClick={() => handleCardClick(blogData)}
                        img={api_urls + 'uploads/' + blogData?.image}
                        views={"200"}
                        description={<div dangerouslySetInnerHTML={{ __html: getShortDescription(blogData?.description) }}></div>}
                        name={blogData?.title}
                        time={moment(blogData?.createdAt).format("DD-MM-YY")}
                    />
                </div>
            ))}
        </main>
    );
}

const mapStateToProps = (state) => ({
    astroBlogData: state.blogreducer.astroBlogData,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(Home);



// import React, { useEffect } from "react";
// import BlogCard from "../../../components/cards/BlogCard";
// import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import * as BlogActions from "../../../redux/actions/blogActions.js";
// import { connect } from "react-redux";
// import cardimg from "../../../assets/images/cardBlog/cardimg.jpg";
// import { api_urls } from "../../../utils/api-urls/index.js";
// import moment from "moment";
// import { getShortDescription } from "../../../utils/common-function/index.js";

// const Home = ({ astroBlogData, dispatch }) => {
//     console.log(astroBlogData)
//     const navigate = useNavigate()

//     const handleCardClick = (blogData) => {
//         navigate("/single-blog-page", {
//             state: { astroBlogData: blogData },
//         });
//     }

//     return (
//         <main className="flex flex-wrap gap-4 py-10 px-[30px]">
//             {astroBlogData && astroBlogData.map(blogData => (
//                 <div key={blogData.id} className="flex-30">
//                     <BlogCard
//                         onClick={() => handleCardClick(blogData)}
//                         img={api_urls + 'uploads/' + blogData?.image}
//                         views={"200"}
//                         description={<div dangerouslySetInnerHTML={{ __html: getShortDescription(blogData?.description) }}></div>}
//                         name={blogData?.title}
//                         time={moment(blogData?.createdAt).format("DD-MM-YY")}
//                     />
//                 </div>
//             ))}
//         </main>
//     );
// }

// const mapStateToProps = (state) => ({
//     astroBlogData: state.blogreducer.astroBlogData,
// });

// const mapDispatchToProps = (dispatch) => ({ dispatch });

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
