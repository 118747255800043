import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as BlogActions from "../../../redux/actions/blogActions.js";
import moment from "moment";
import { getShortDescription } from "../../../utils/common-function/index.js";
import { api_urls } from "../../../utils/api-urls/index.js";
import BlogCard from "../../../components/cards/BlogCard";

const RelatedBlog = ({ astroBlogData, dispatch, blogCategory }) => {
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch blogs based on blogCategory
        dispatch(BlogActions.getAstroBlogs(blogCategory));
    }, [dispatch, blogCategory]);

    const handleCardClick = (blogData) => {
        // Scroll to top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });

        // Navigate to single blog page
        navigate("/single-blog-page", {
            state: { astroBlogData: blogData },
        });
    }

    // Check if astroBlogData is null or undefined
    if (!astroBlogData) {
        return <div>Loading...</div>; // or any loading indicator
    }

    // Get only the latest four blogs
    const latestFourBlogs = astroBlogData.slice(0, 4);

    return (
        <main className="flex flex-wrap gap-4 py-[20px]">
            {latestFourBlogs.map(blogData => (
                <div key={blogData.id} className="flex-20">
                    <BlogCard
                        onClick={() => handleCardClick(blogData)}
                        img={api_urls + 'uploads/' + blogData?.image}
                        views={"200"} // Placeholder
                        description={<div dangerouslySetInnerHTML={{ __html: getShortDescription(blogData?.description) }}></div>}
                        name={blogData?.title}
                        time={moment(blogData?.createdAt).format("DD-MM-YY")}
                    />
                </div>
            ))}
        </main>
    );
}

const mapStateToProps = (state) => ({
    astroBlogData: state.blogreducer.astroBlogData,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(RelatedBlog);
